// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

// ----- COLORS

$nps-light-red: #EB6F76 !default;
$nps-light-red--transparent: rgba($nps-light-red, 0.42) !default;
$nps-dark-red: #C32C2C !default;
$nps-light-orange: #F1943D !default;
$nps-light-orange--transparent: rgba($nps-light-orange, 0.42) !default;
$nps-dark-orange: #B26216 !default;
$nps-light-yellow: #EDC600 !default;
$nps-light-yellow--transparent: rgba($nps-light-yellow, 0.42) !default;
$nps-dark-yellow: #887617 !default;
$nps-light-green: #8AC89A !default;
$nps-light-green--transparent: rgba($nps-light-green, 0.42) !default;
$nps-dark-green: #24883D !default;

// --------------------------------------------------
// STYLE
// --------------------------------------------------

// ----- BOTTOM SHEET WRAPPER (MOBILE) & DIALOG WRAPPER (DESKTOP)

.nps-bottom-sheet-wrapper,
.nps-dialog-wrapper {

    .mat-bottom-sheet-container,
    .mat-mdc-dialog-surface {
        display: contents !important; // Hide Material container to show nps component entry and leave animations
    }
}

// ----- COMPONENT

app-nps {
    overflow: hidden;
    max-height: 80vh;
    color: $black-c;
    background-color: $white-c;
    line-height: 1.2;

    .nps-bottom-sheet-wrapper & { // If nps component is inside bottom sheet wrapper (mobile)
        width: 100vw;
        border-radius: 24px 24px 0 0;
    }

    .nps-dialog-wrapper & { // If nps component is inside dialog wrapper (desktop)
        border-radius: 18px;
    }

    .nps {
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        width: min-content;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        padding: 4.2rem 3.6rem 3.6rem;

        @include media-breakpoint(gt-sm) { // If the screen is greater than 960px (desktop)
            padding: 3.6rem 3.6rem 2.4rem;
        }

        .nps__header {

            .nps__title {
                margin: unset;
                text-align: center;
                font-size: 2rem;
                font-weight: 800;
                line-height: inherit;
            }
        }

        .nps__content {
            padding: 3rem 0;

            @include media-breakpoint(gt-sm) { // If the screen is greater than 960px (desktop)
                padding: 3.6rem 0 0;
            }

            .nps__form {

                .nps__radio-group {
                    display: flex;
                    flex-direction: column;
                    row-gap: 1.2rem;
                    align-items: center;

                    @include media-breakpoint(gt-sm) { // If the screen is greater than 960px (desktop)
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-start;
                    }

                    .nps__radio-subgroup {
                        display: flex;
                        gap: 1.2rem;
                        transition: background-image 100ms ease-in-out;

                        // First subgroup of radio buttons
                        &:first-child {
                            max-width: 100%;
                            padding: 1.2rem 0.6rem 1.2rem 1.2rem;
                            background-image: linear-gradient(
                                                  to right,
                                                  $nps-light-red--transparent 0% 56.06%,
                                                  $nps-light-orange--transparent 71.46% 87.24%,
                                                  $white-c 100%
                                              );
                            border-top-left-radius: 1000px;
                            border-bottom-left-radius: 1000px;

                            @include media-breakpoint(gt-sm) { // If the screen is greater than 960px (desktop)
                                background-image: linear-gradient( // Edit background gradient
                                                      to right,
                                                      $nps-light-red--transparent 0% 56.06%,
                                                      $nps-light-orange--transparent 71.46% 87.24%
                                                  );
                            }

                            .nps__radio-button {

                                // Red radio buttons (from the first subgroup)
                                &:nth-child(1),
                                &:nth-child(2),
                                &:nth-child(3),
                                &:nth-child(4) {
                                    color: $nps-dark-red;
                                    border-color: $nps-light-red;

                                    &:hover,
                                    &:focus {
                                        border-color: $nps-dark-red;
                                    }

                                    &:active,
                                    &.nps__radio-button--checked {
                                        color: $white-c;
                                        background-color: $nps-dark-red;
                                        border-color: $nps-dark-red;

                                        .mdc-form-field {

                                            .mdc-label {

                                                .nps__radio-button-text-label {
                                                    background-color: $nps-light-red--transparent;
                                                }
                                            }
                                        }
                                    }

                                    .mdc-form-field {

                                        .mdc-label {

                                            .nps__radio-button-text-label {
                                                color: $nps-dark-red;
                                            }
                                        }
                                    }
                                }

                                // Orange radio buttons (from the first subgroup)
                                &:nth-child(5),
                                &:nth-child(6) {
                                    color: $nps-dark-orange;
                                    border-color: $nps-light-orange;

                                    &:hover,
                                    &:focus {
                                        border-color: $nps-dark-orange;
                                    }

                                    &:active,
                                    &.nps__radio-button--checked {
                                        color: $white-c;
                                        background-color: $nps-dark-orange;
                                        border-color: $nps-dark-orange;

                                        .mdc-form-field {

                                            .mdc-label {

                                                .nps__radio-button-text-label {
                                                    background-color: $nps-light-orange--transparent;
                                                }
                                            }
                                        }
                                    }

                                    .mdc-form-field {

                                        .mdc-label {

                                            .nps__radio-button-text-label {
                                                color: $nps-dark-orange;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // Second (and last) subgroup of radio buttons
                        &:last-child {
                            max-width: 84%;
                            padding: 1.2rem 1.2rem 1.2rem 0.6rem;
                            background-image: linear-gradient(
                                                  to right,
                                                  $white-c 0%,
                                                  $nps-light-orange--transparent 15%,
                                                  $nps-light-yellow--transparent 33.71% 52.16%,
                                                  $nps-light-green--transparent 70% 100%
                                              );
                            border-top-right-radius: 1000px;
                            border-bottom-right-radius: 1000px;

                            @include media-breakpoint(gt-sm) { // If the screen is greater than 960px (desktop)
                                background-image: linear-gradient( // Edit background gradient
                                                      to right,
                                                      $nps-light-orange--transparent 15%,
                                                      $nps-light-yellow--transparent 33.71% 52.16%,
                                                      $nps-light-green--transparent 70% 100%
                                                  );
                            }

                            .nps__radio-button {

                                // Orange radio button (from the second subgroup)
                                &:nth-child(1) {
                                    color: $nps-dark-orange;
                                    border-color: $nps-light-orange;

                                    &:hover,
                                    &:focus {
                                        border-color: $nps-dark-orange;
                                    }

                                    &:active,
                                    &.nps__radio-button--checked {
                                        color: $white-c;
                                        background-color: $nps-dark-orange;
                                        border-color: $nps-dark-orange;

                                        .mdc-form-field {

                                            .mdc-label {

                                                .nps__radio-button-text-label {
                                                    background-color: $nps-light-orange--transparent;
                                                }
                                            }
                                        }
                                    }

                                    .mdc-form-field {

                                        .mdc-label {

                                            .nps__radio-button-text-label {
                                                color: $nps-dark-orange;
                                            }
                                        }
                                    }
                                }

                                // Yellow radio buttons (from the second subgroup)
                                &:nth-child(2),
                                &:nth-child(3) {
                                    color: $nps-dark-yellow;
                                    border-color: $nps-light-yellow;

                                    &:hover,
                                    &:focus {
                                        border-color: $nps-dark-yellow;
                                    }

                                    &:active,
                                    &.nps__radio-button--checked {
                                        color: $white-c;
                                        background-color: $nps-dark-yellow;
                                        border-color: $nps-dark-yellow;

                                        .mdc-form-field {

                                            .mdc-label {

                                                .nps__radio-button-text-label {
                                                    background-color: $nps-light-yellow--transparent;
                                                }
                                            }
                                        }
                                    }

                                    .mdc-form-field {

                                        .mdc-label {

                                            .nps__radio-button-text-label {
                                                color: $nps-dark-yellow;
                                            }
                                        }
                                    }
                                }

                                // Green radio buttons (from the second subgroup)
                                &:nth-child(4),
                                &:nth-child(5) {
                                    color: $nps-dark-green;
                                    border-color: $nps-light-green;

                                    &:hover,
                                    &:focus {
                                        border-color: $nps-dark-green;
                                    }

                                    &:active,
                                    &.nps__radio-button--checked {
                                        color: $white-c;
                                        background-color: $nps-dark-green;
                                        border-color: $nps-dark-green;

                                        .mdc-form-field {

                                            .mdc-label {

                                                .nps__radio-button-text-label {
                                                    background-color: $nps-light-green--transparent;
                                                }
                                            }
                                        }
                                    }

                                    .mdc-form-field {

                                        .mdc-label {

                                            .nps__radio-button-text-label {
                                                color: $nps-dark-green;
                                            }
                                        }
                                    }
                                }

                                .mdc-form-field {

                                    .mdc-label {

                                        .nps__radio-button-text-label {

                                            @include media-breakpoint(lt-md) { // If the screen is smaller than 960px (mobile)
                                                top: unset;
                                                bottom: -2.1rem;
                                                transform: translateY(100%);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .nps__radio-button {
                            position: relative;
                            width: 4.8rem;
                            min-width: 3rem;
                            height: auto;
                            aspect-ratio: 1;
                            background-color: $white-c;
                            border: 2px solid;
                            border-radius: 50%;
                            cursor: pointer;
                            transition: color 100ms ease-in-out,
                                        background-color 100ms ease-in-out,
                                        border-color 100ms ease-in-out;

                            .mdc-form-field {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                                color: inherit;
                                border-radius: inherit;

                                .mdc-radio {
                                    @include visually-hidden;
                                }

                                .mdc-label {
                                    display: contents;
                                    cursor: inherit;

                                    .nps__radio-button-number-label {
                                        font-size: 2rem;
                                        font-weight: 700;
                                    }

                                    .nps__radio-button-text-label {
                                        position: absolute;
                                        top: -2.1rem;
                                        transform: translateY(-100%);
                                        width: max-content;
                                        padding: 0.6rem 1.2rem;
                                        border-radius: 18px;
                                        font-size: 1.4rem;
                                        font-weight: 600;
                                        font-style: italic;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .nps__footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2.4rem;

            .nps__skip-button,
            .nps__validate-button {
                margin: unset;
                border: unset;
                line-height: inherit;
            }

            .nps__skip-button {
                padding: 0.6rem 1.2rem;
                color: #5D5D5D;
                background-color: unset;
                font-size: 1.4rem;
                font-weight: 600;
                transition: color 100ms ease-in-out;

                &:hover,
                &:focus,
                &:active {
                    color: inherit;
                }
            }

            .nps__validate-button {
                padding: 0.9rem 1.8rem;
                border-radius: 1000px;
                color: $white-c;
                background-color: #1E4FFF;
                font-size: 1.6rem;
                font-weight: 700;
                transition: color 100ms ease-in-out,
                            background-color 100ms ease-in-out;

                &:hover,
                &:focus,
                &:active {
                    background-color: scale-color(#1E4FFF, $lightness: -15%);
                }

                &[disabled] {
                    color: rgba($black-c, 0.14);
                    background-color: #F1F1F1;
                }
            }
        }
    }
}