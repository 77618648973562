// This file is meant to be imported only once!
@use '@angular/material' as mat;

// Perfect scrollbar
@import url('~perfect-scrollbar/css/perfect-scrollbar.css');

// Fuse
@import "../../../@fuse/scss/fuse"; // vient charger theme

// Include core mixin (prerequisite styles for common features used by multiple components)
@include mat.core();

// Include custom theme mixin (must be wrapped in a selector)
html {
    @include mat.all-component-themes($theme);
}

// Partials
@import "../../../@fuse/scss/partials/reset";
@import "../../../@fuse/scss/partials/normalize";
@import "../../../@fuse/scss/partials/scrollbars";
@import "../../../@fuse/scss/partials/helpers";
@import "../../../@fuse/scss/partials/global";
@import "../../../@fuse/scss/partials/icons";
@import "../../../@fuse/scss/partials/colors";
@import "../../../@fuse/scss/partials/material";
@import "../../../@fuse/scss/partials/angular-material-fix";
@import "../../../@fuse/scss/partials/typography";
@import "../../../@fuse/scss/partials/docs";
@import "../../../@fuse/scss/partials/page-layouts";
@import "../../../@fuse/scss/partials/cards";
@import "../../../@fuse/scss/partials/navigation";
@import "../../../@fuse/scss/partials/forms";
@import "../../../@fuse/scss/partials/toolbar";
@import "../../../@fuse/scss/partials/print";

// Plugins
@import "../../../@fuse/scss/partials/plugins/plugins";