.mat-icon[svgicon="earth"] svg path {
    fill: grey;
}

.mat-toolbar {
    font-size: inherit;
}

.tralalere-bar + * .cdk-overlay-pane {
    margin-top: 73px;
}

app-tralalere-bar {
    mat-toolbar-row {
        padding: 0 10px !important;

        .mat-mdc-icon-button {
            width: inherit;
            height: inherit;

            mat-icon {
                width: 130px;
                height: 48px;
            }
        }
    }
}

.toolbarMessage {
    background-color: #FCB731;
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
    text-align: center;
    font-weight: bold;
    padding: 0 8%;
    color: red;
    flex-wrap: wrap;

    a {
        color: #004157 !important;
        text-decoration: underline !important;
    }
}