app-home-page {

    .container-banner-home-header {
        margin: 20px 0;
        width: calc(100% - 60px);
        border-radius: 5px;
        position: relative;
    }

    .banner {
        position: relative;
        height: 250px;
        margin: 20px 0;
        border-radius: 5px;
        max-height: 160px;
        height: 160px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .home-header {
        width: 100%;
        margin: 20px 0;
        position: absolute;
        top: 0;
        max-height: 160px;
        height: 160px;
        color: white;
        background-image: linear-gradient(90deg, #323A49 41.15%, rgba(50, 58, 73, 0.83) 66.34%);
        border-radius: 5px;
        padding: 5px;
    }

    .content-cards {
        width: calc(100% - 60px);
        gap: 25px 2%;
        flex-flow: row wrap;
        box-sizing: border-box;
        display: flex;

        &.home-page__progression {

            .mat-mdc-card-title {
                display: flex;
                align-content: center;
                align-items: center;

                .learners-count {
                    padding-left: 40px;
                    margin-left: auto;
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 5px;
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                        min-height: 24px;
                        font-size: 24px;
                        line-height: 24px;
                        transform: translateY(-50%);
                        @include svgBackgroundColor('group.svg', var(--blanc, #fff) );
                    }
                }
            }
            .wrapper-inner-content-card {
                width: 100%;
                margin-bottom: 20px;
                gap: 10px;

                .inner-content-card {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    border-radius: 4px;
                    border: 1px solid #FFF;
                    word-break: break-word;

                    padding: 40px 50px 10px 10px;

                    background: rgba(250, 250, 250, 0.80);
                    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06);

                    position: relative;

                    color: var(--text);

                    &:before, &:after {
                        position: absolute;
                        content: '';
                        display: block;
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                        min-height: 24px;
                        font-size: 24px;
                        line-height: 24px;
                    }

                    &.collective-assignment:before {
                        top: 10px;
                        background-image: icon('assignation.svg');
                    }
                    &.individual-assignment:before {
                        top: 10px;
                        background-image: icon('assignation.svg');
                    }
                    &:after {
                        top: 50%;
                        right: 10px;
                        transform: translateY(-50%);
                        background-image: icon('goto.svg');
                    }
                    opacity: 0.5;
                    &.active {
                        opacity: 1;
                        border: 1px solid var(--Rouge_Tralalere_accessible, #E62716);
                    }

                }
            }
            .mat-mdc-card-actions button {
                flex-direction: row;
                box-sizing: border-box;
                display: flex;
                place-content: flex-start flex-end;
                align-items: center;
                line-height: 36px;
                gap: 11px;
                padding: 0 8px 0 11px;
                line-height: 36px;
                &[disabled] {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

            }
        }
    }

    .title {
        text-decoration: none;
        position: relative;
        font-style: normal;
        letter-spacing: 1.5px;
        color: map-get($primary, 500);
        flex: 1; // 1/8
        display: flex;
        align-items: center;
        margin: 0;
        /* H1 Bold 24 */
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
    }

    .content {
        flex: 1; // 1/8
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;

        p {
            letter-spacing: 1.5px;
            color: map-get($primary, 500);
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 15px;
        }
    }

    .mat-mdc-card {
        padding: 0;
        height: 250px;
        overflow: inherit;
        background: #FFFFFF;
        box-shadow: 0px 12px 40px -25px rgba(0, 0, 0, 0.5);
        border-radius: 5px;

        .wrapper-content {
            height: 100%;
            width: 100%;
            padding: 15px 20px;
        }

        .mat-mdc-card-header {
            display: flex;
            align-items: center;
            border-radius: 5px 5px 0 0;

            .mat-mdc-card-header-text {
                margin: 0 10px;
                width: 100%;
            }

            .mat-mdc-card-title {
                margin: 0;
                color: #fff;
                font-size: 19px;
                line-height: 20px;
                font-weight: 700;
                width: 100%;

                .image-title {
                    width: 53px;
                    height: 53px;
                    background-color: white;
                    border: 2px solid #323A49;
                    border-radius: 5px;
                    position: absolute;
                    margin-top: -10px;
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }

                h2 {
                    padding-left: 10px;
                }
                .image-title + h3 {
                    padding-left: 70px;
                }
            }
        }

        .mat-mdc-card-img {
            color: #fff;
            width: 140px;
            min-width: 140px;

            img {
                margin-bottom: 0;
                width: 140px;
                height: 140px;
                max-width: 140px;
                max-height: 140px;
            }
        }

        .mat-mdc-card-content {
            flex: 1 1 auto !important;
            overflow: auto;
            margin-bottom: 0;
            height: inherit;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            width: 100%;

            dl {
                margin: 0;

                ul {
                    margin: 0;
                    padding: 0 10px;
                    list-style-position: inside;
                }
            }
        }

        .mat-mdc-card-actions {
            margin: 0;
            padding: 0;
            flex: 0 0 auto !important;

            button {
                border-radius: 16px;
                padding: 0 36px;
            }

        }
    }
}

// NPS dialog
.radios-container {
    .mdc-radio, .mdc-radio__outer-circle, .mdc-radio__inner-circle, input {
        display: none;
    }
    .mdc-label {
        padding: 8px 18px;
        font-size: 18px;
        margin: 4px
    }
}