$app-media-upload-background: $media-background;

app-media-upload, .app-media-upload {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 21.4rem;
    min-width: 150px;
    aspect-ratio: 16 / 9;
    width: unset;
    height: unset;
    overflow: hidden;
    position: relative;

    padding: $gap-m;
    // Style
    border-radius: $radius-xl;
    background: $app-media-upload-background;

    audio {
        width: 100%;
    }

    .app-media-upload__spinner {

        svg {

            circle {
                stroke: $white-c;
            }
        }
    }

    img, video {
    }
    img {
        // Layout
        position: absolute;
        z-index: 0;
        width: 100%; // The image element fills the entire width of its parent
        height: auto; // Auto adjust the height of the image element depending on the original ratio of the image itself
        max-height: 100%; // Set the maximum height of the image element to prevent it from overflowing its parent
        aspect-ratio: 16 / 9;
        object-fit: cover;
    }

    &.image_placeholder::before,
    &.audio_placeholder::before {
        position: absolute;
        inset: 0;
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        // background: icon('media_image_placeholder.svg', 'activities') no-repeat center;
        background-size: 35%;
        background-position: center;
        mask-size: 35%;
        mask-position: 50%;
        -webkit-mask-size: 35%;
        -webkit-mask-position: 50%;
        opacity: 0.4;
    }

    &.image_placeholder::before { @include svgBackgroundColor('media_image_placeholder.svg', var(--blanc, #fff), 'activities' ); }
    &.audio_placeholder::before { @include svgBackgroundColor('media_audio_placeholder.svg', var(--blanc, #fff), 'activities' ); }



button.app-media-upload__fileInput {
    @include button("tertiary", $size: m, $icon-on-Right: true);
    position: absolute;
    z-index: 1;
    inset: auto $gap-m $gap-m;
    // style ILE
    border-radius: $radius-rounded;
    border: 2px solid rgba(0, 41, 67, 0.25);
    background: #FFF;

}
button.app-media-upload__onActivateDeleteClick {
    @include button("secondary", $size: m, $text-hide: true );
    position: absolute;
    z-index: 1;
    inset: auto $gap-m $gap-m auto;
}
}