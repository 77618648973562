//html {
//    font-size: $root-em; // Root element font-size
//}
//
//body {
//    @extend %body1;
//    color: var(--text);
//}
//
//html,
//body {
//    display: flex;
//    flex: 1 0 auto;
//    width: 100%;
//    height: 100%;
//    max-height: 100%;
//    min-height: 100%;
//    margin: 0;
//    padding: 0;
//    overflow: hidden;
//    background: #F5F5F5;
//}
//
//h1,
//.h1 {
//    @extend %h1;
//}
//
//h2,
//.h2 {
//    @extend %h2;
//}
//
//h3,
//.h3 {
//    @extend %h3;
//}
//
//h4,
//.h4 {
//    @extend %h4;
//}
//
//b,
//.bold {
//    @extend %body2;
//}
//
//caption,
//.caption {
//    @extend %caption;
//}
//
//button,
//.button {
//}
//
//input,
//.input {
//    @extend %input;
//}

a {
    color: $link--color;
    text-decoration: $link--text-decoration;
}


fuse-app-lessons .page-layout {
    flex: 1;
    display: flex;
    flex-direction: column;

    //box-shadow: inset 0 0 0px 10px #d000ff;
    //border: 5px solid #de5eff;
}

fuse-app-lessons .content, {
    flex: 1;
    display: flex;
    flex-direction: column;

    //box-shadow: inset 0 0 0px 10px #00ff24;
    overflow: auto;
}

// ****  @modules  ****
// --> voir base/base
fuse-app-lessons router-outlet + *:not(router-outlet) { // Cible les modules que l'on charge
    // Layout
    display: flex;
    flex-direction: column; // organisation verticale
    flex-grow: 1; // occupe l'espace vertical disponible (PAS DE height 100% !)
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
}