// https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
#login div#login-form-wrapper #login-form, div#forgot-password-form, #register #register-form {
            color: $fuse-authentication-color;
            background: $fuse-authentication-background !important;
            border-radius: #{$fuse-authentication-border-radius};
            box-shadow: $fuse-authentication--box-shadow;

            a {
                color: $fuse-authentication__link--color;
                text-decoration: $fuse-authentication__link--text-decoration;
            }

            > .submit-button{
                margin: 4em 0 2em;
            }

            .title {
                font: #{$fuse-authentication-title-font};
                letter-spacing: #{$fuse-authentication-title-letter-spacing};
            }
            .mat-mdc-form-field-label {
                color: $default-font-color;
                font-weight: 500;
                transition: color 100ms ease-in-out,
                            font-weight 100ms ease-in-out,
                            transform 100ms ease-in-out;
            }
            .mat-mdc-checkbox {
                .mdc-form-field {
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    .mdc-checkbox { // la case
                        flex: none; // ne s'étend pas
                    }
                    .mdc-label {
                        white-space: normal;
                        display: flex;
                        flex-wrap: wrap; // les enfants vont à la ligne
                        flex: 1; // j'occupe tout l'espace dispo
                    }
                }
            }

            jhi-re-captcha {
                margin-top: 20px;
            }
            
            .mdc-checkbox__background {
                border-color: map_get($mat-fusedark, 100);
            }

            .mat-form-field-appearance-fill {

                .mat-mdc-form-field-flex {
                    margin-top: 1.8em;
                    background-color: #{$white-c};
                    border: $fuse-authentication__field-flex--border;
                    border-bottom: $fuse-authentication__field-flex--border-bottom;
                    border-radius: $fuse-authentication__field-flex--border-radius;
                }

                .mat-mdc-form-field-infix {
                    padding: #{$fuse-authentication-field-infix-padding};
                    border: none;

                    .mat-mdc-form-field-label-wrapper {
                        overflow: visible;
                        padding: 0;
                        top: -0.5em;

                        .mat-mdc-form-field-label {
                            color: $fuse-authentication__label--color;
                            text-transform: inherit;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
                &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float {

                    .mat-mdc-form-field-label-wrapper {
                        top: -1.3em;
                        padding: 0;

                        .mat-mdc-form-field-label {
                            transform: translateY(-1.5em) scale(1);
                            color: $fuse-authentication__label--float--color;
                            font-weight: 400;
                        }
                    }

                }

                .mat-mdc-form-field-prefix, .mat-mdc-form-field-suffix {
                    align-self: center;
                    padding-right: 10px;
                    color: #{$primary-c};
                }

                .mat-mdc-input-element, .mat-mdc-select  {
                    font: #{$fuse-authentication-field-input-font};
                    font-size: 18px;
                    background-color: transparent !important;
                    color: black;

                    margin-bottom: 4px;
                    margin-top: 6px;
                }
                /* Change the white to any color ;) */
                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active  {
                    -webkit-box-shadow: 0 0 0 30px map_get($mat-fusedark, 100) inset !important;
                }

                input:-internal-autofill-selected {
                    background-color: transparent !important;
                    background-image: none !important;
                    color: -internal-light-dark-color(black, transparent) !important;
                }

                .mat-mdc-form-field-label {

                }

                .mat-mdc-form-field-underline::before,
                .mat-mdc-form-field-ripple,
                .mat-focused .mat-mdc-form-field-ripple,
                .mat-mdc-form-field-underline::before,
                .mat-mdc-form-field-ripple,
                .mat-focused .mat-mdc-form-field-ripple {
                    margin-top: 5px;
                    background-color: transparent;
                }

            }

            button.submit-button {
                margin-top: 40px;
                margin-left: auto;
                margin-right: auto;


                &:disabled {
                    opacity: 40%;
                    span {
                        cursor: not-allowed;
                    }
                }
            }


}
#login {

    div#login-form-wrapper { // TODO supprimer de default pour s'en passer ici

        #login-form {
            position: relative;
            width: $fuse-authentication-login-width; // 384px;
            max-width: $fuse-authentication-login-width;
            .logo {
                margin: #{$fuse-authentication-logo-margin};
                width: $fuse-authentication-logo-width;
                .mat-icon {
                    width: inherit;
                    height: inherit;
                    min-width: inherit;
                    min-height: inherit;
                    line-height: inherit;
                }
            }
            .title {
                margin: #{$fuse-authentication-title-margin};
            }

            .divider{
                margin: 2em 0;
                color: $fuse-authentication-color;
                font-weight: bold;
                display: block;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;

                > span {
                    text-transform: uppercase;
                    position: relative;
                    display: inline-block;

                    &:before, &:after{
                        content: "";
                        position: absolute;
                        top: 45%;
                        width: 9999px;
                        height: 2px;
                        background: $fuse-authentication-color;
                    }

                    &:before {
                        right: 100%;
                        margin-right: 15px;
                    }
                    &:after {
                        left: 100%;
                        margin-left: 15px;
                    }
                }
            }
            .register {
                padding: 0;
                margin: 0;
                position: absolute;
                bottom: 115px;
                width: calc(100% - 64px);
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
        }

    }

}
#register-form {

    .logo { display: none !important; }
    .mat-mdc-form-field {
        padding: 0 5px;


        &.field-password {
            max-width: 100% !important;
        }

    }
}

#forgot-password-form {
    width: 483px;
    max-width: 100%;
    .logo {
        width: auto !important;
        margin: 62px 0 0 0 !important;
        .mat-icon {
            width: 274px;
            height: 80px;
            min-width: inherit;
            min-height: inherit;
            line-height: inherit;
        }

    }
}

.user-blocked#login div#login-form-wrapper #login-form {
    a {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
    h1{
        padding-top: 5%;
        font-size: 21px;
    }
    p{
        padding: 5% 0;
    }
}