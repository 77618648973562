@use "sass:map";

// app-pretty-card button-menu
.mat-mdc-menu-panel.editor-panel__button-menu {
    margin-top: 10px;

    .mat-mdc-menu-content {
        background: #323A49;

        .mat-mdc-menu-item {
            @include button("dark", $icon-on-Right: false, $flat-button: true);
        }
    }
}

app-pretty-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: $default-font-color;
    background-color: $white-c;
    border: 1px solid #E7E7E7;
    border-radius: 18px;
    box-shadow: 0px 10px 15px 0px rgba(36, 38, 55, 0.10),
                0px 2px 2px 0px rgba(36, 38, 55, 0.05),
                0px 4px 4px 0px rgba(255, 255, 255, 0.15);

    .app-pretty-card__button-menu, .editor-panel__button-edit-activity {
        @include button("tertiary", $size: s, $text-hide: true); // TODO
        justify-content: center;
        aspect-ratio: 3 / 2;
        margin-left: auto;
        border: 1px solid #E7E7E7;
        border-radius: 10px;
        box-shadow: 0px 4px 10px 0px rgba($black-c, 0.03),
                    0px -2px 4px 0px rgba($black-c, 0.02),
                    0px 2px 4px 0px rgba($white-c, 0.25);

        mat-icon.mat-icon {
            color: map.get($button-tertiary, color);
            width: 100%;
            height: 100%;
            position: relative;

            svg {
                width: auto;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
            }
        }
    }

    .editor-panel__button-edit-activity {
        margin-left: unset;
    }

    .app-pretty-card__button-move_right,
    .app-pretty-card__button-move_left {
        @include button("tertiary", $text-hide: true);
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 0;
        flex: 1.6;
        justify-content: center;

        mat-icon.mat-icon {
            width: 100%;
            height: 100%;
            position: relative;

            svg {
                width: auto;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
            }
        }
    }

    .app-pretty-card__button-move_right {
        margin-left: 0;
        border-left: 1px solid #E7E7E7;
    }

    .app-pretty-card__button-move_left {
        margin-right: 0;
        border-right: 1px solid #E7E7E7;
    }

    .location-form {
        .location-form-denominator {
            @include body-font;
            font-weight: 600;
            color: transparentize(#242637, 0.5);
        }
    }

    // Layouts
    .app-pretty-card {
        &__header {
            flex: 1;
            display: flex;
            justify-content: space-between;
            max-height: 6rem;
            padding: 1.5rem 1.5rem 0 1.5rem;
        }

        &__content {
            flex: 6;
            display: flex;
            flex-direction: column;

            // --------------------------------------------------
            // La Consigne
            // --------------------------------------------------
            h3.pretty-activity-card__activity-name {
                @include body-font-size;
            }

            // --------------------------------------------------
            // Le type d'exercice
            // --------------------------------------------------
            .pretty-activity-card__activity-name,
            .pretty-activity-card__typology-label {
                margin: 0;
                text-align: center;
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                min-height: 3.0rem;
                line-height: initial;
                overflow-wrap: anywhere; // Prevent unbreakable string overflow
            }

            .pretty-activity-card__activity-name {
                @include body-font-size;
                padding: 1rem 1.5rem;
            }

            .pretty-activity-card__typology-label {
                border-block: 1px solid #E7E7E7;
                padding: 0.5rem 1.5rem;
            }

            app-audio-time, app-video-time, .time-info {
                display: flex;
                border-radius: 2px;
                background: #FFF;
                color: $default-font-color;
                padding: 0.5rem 1rem;
            }
        }

        &__footer {
            flex: 1;
            display: flex;
            justify-content: space-between;
            max-height: 6rem;
            padding: 0;
            margin: 0;

            input {
                margin-right: 3px;
                padding: 1px 0 0 0;

                border: 1px solid #CBD1DB;
                color: $default-font-color;

                border-radius: 5px;
                @include body-font;
                font-weight: 600;
                text-align: center;
                fill: var(--Blanc, #FFF);
                stroke-width: 1px;
                stroke: var(--Grisdecontour, #CBD1DB);

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button { // chrome     hide arrow
                    -webkit-appearance: none;
                    margin: 0;
                }

                -moz-appearance: textfield; // Firefox hide arrow

            }

            .location-form, .location-form-denominator {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
            }

            .location-form {
                flex: 3;
                display: flex;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                background-color: #F6F6F7;
            }
        }
    }


    // --------------------------------------------------
    // Le bouton Sélectionner
    // --------------------------------------------------
    .app-pretty-card__content + button {
        @include button(primary, $icon-on-Right: true, $fullwidth: true);
        max-height: 12%;
        flex: 1;
        justify-content: center;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    // --------------------------------------------------
    // La couverture carte sélectionnée
    // --------------------------------------------------
    .active & { // = .active app-pretty-card .app-pretty-card__content
        .app-pretty-card__content {
            position: relative;
        }

        .app-pretty-card__content::before {
            position: absolute;
            z-index: 100;
            content: '';
            display: block;
            inset: 0;
            background-color: transparentize($primary-c, 0.1);
            box-shadow: 0 4px 4px 0 rgba(255, 255, 255, 0.25) inset;
        }

        .app-pretty-card__content::after {
            position: absolute;
            z-index: 150;
            content: '';
            display: block;
            inset: 0;
            @include svgBackgroundColor('check.svg', lighten(saturate(#01844d, 1.50), 23.92));
            mask-repeat: no-repeat;
            mask-size: 60%;
            mask-position: center;
        }

        button { // begin a cancel button
            @include button("tertiary", $button-bordered: true);
            justify-content: center;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: inherit;
            border-bottom-left-radius: inherit;
        }
    }


}