app-notepad-list {

    .close {
        @extend %button--square-icon !optional;
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        margin: 18px 12px;
    }

    .mat-mdc-progress-spinner {

        svg {

            circle {
                stroke: var(--accent);
            }
        }
    }
    
    .notepad-card-list {
        flex-wrap: wrap;
        overflow-y: auto;

        .fake-card .mat-mdc-card {
            display: flex;
            justify-content: center;
            background: $accent-c;
            border-radius: 13.9941px;
            min-height: 278px;
            height: auto;
            color: white;
        }

        .fake-card .mat-mdc-card-content {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-left: 0 !important;
        }

        .fake-card .mat-mdc-card-content mat-icon {
            height: 57px;
            width: 57px;
        }
    }
}
fuse-progress-bar { display: none; }
vertical-layout-1 #main > .container > .container > .container content > app-notepad-content-editor > .page-layout > .content {
    padding: 0 !important;
}

app-notepad-content-editor {

    mat-spinner {
        margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the page
    }

    .content-editor {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        place-content: stretch flex-start;
        align-items: stretch;

        .notepad-header {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            padding-block: 15px;
            padding-inline: 20px;
            font-size: 20px;

            .close {
                @extend %button--square-icon !optional;
            }
        }

        .notepad-content {
            height: 100%;
            max-height: 50vh;
            flex: 1 1 auto;

            ckeditor,
            .ck-editor__main,
            .ck-reset,
            .ck-content {
                height: 100%;
            }
        }

        .notepad-action {
            display: flex;
            flex-direction: row;
            flex: 1 0 auto;
            place-content: center;
            align-items: center;

            button {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                mat-spinner {
                    margin: 0;
                }
            }
        }
    }
}


mat-dialog-container .mat-mdc-dialog-surface app-notepad-data-editor-modal {
    .mat-mdc-dialog-content {

        form {

            mat-list-item {
                mat-spinner {
                    margin: 0;
                }
            }
        }

        mat-spinner {
            margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the modal
        }
    }
}

app-notepad-card.card-split-media-info {
    mat-card-content {
        height: 100%;

    }
    mat-card-actions.mat-mdc-card-actions  {
        place-content: flex-end;
    }

}

app-notepad-card {

    mat-chip,
    mat-chip-option,
    mat-chip-row {

        mat-icon {
            color: #037CC2 !important;
            font-size: 20px;
        }

        div {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
}