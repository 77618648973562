@mixin grid-aaa-bbc {
    display: grid !important;
    grid-template:
        "a a a" auto
        "b b b" auto
        "c c c" auto;

    @include media-breakpoint-up('sm') {
        grid-template:
        "a a a" auto
        "b b c" auto / 1fr 1fr 1fr;
    }

    //a
    > *:nth-child(1) {
        grid-area: a;
    }
    //b
    > *:nth-child(2) {
        grid-area: b;
    }
    //c
    > *:nth-child(3) {
        grid-area: c;
    }


}

@mixin flex-aaa-bbc {
    display: flex;
    //a et b
    > *:nth-child(1) {
        flex: 1 1 100%;
    }
    //b
    > *:nth-child(2) {
        flex: 2;
    }
    //c
    > *:nth-child(3) {
        flex: 1;
    }
}

@function _buildIcon($icon) {
    $icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 33" width="100%" height="100%">#{$icon}</svg>';
    @return $icon;
}

@function _buildPath($path, $parameters) {
    $icon: '<path fill="#{map-get($parameters, color)}" stroke="#{map-get($parameters, stroke-color)}" stroke-width="#{map-get($parameters, stroke-width)}" style="#{map-get($parameters, css)}" d="#{$path}" />';
    @return $icon;
}

@function svg-arrow(
    $icon-name,
    $color,
    $stroke-color: transparent,
    $stroke-width: 0,
    $css: '' // arbitrary css
) {
    $parameters: (
        'color': $color,
        'stroke-color': $stroke-color,
        'stroke-width': $stroke-width,
        'css': $css
    );

    $icons: (
        nock: _buildPath('M2 1h15v31H2l13-15.5Z', $parameters),
        point: _buildPath('M0 33V0l15 16.5z', $parameters)
    );

    $icon: _buildIcon(map-get($icons, $icon-name));
    @return url("data:image/svg+xml;charset=utf8,#{$icon}");
}

@mixin arrow($primary, $accent, $border-size ) {
    display: inline-flex;
    position: relative;

    span {
        z-index: 2;
        flex: 1;
        padding: 5px 7px 5px 22px;
        color: $accent;
        background: $primary;
        border-top: $border-size solid $primary;
        border-bottom: $border-size solid $primary;
        align-self: normal;
        display: flex;
        align-items: center;
    }


    &:has(li:first-child) {
        border: 3px solid red;
    }

    &:before, &:after {
        content: "";
        position: absolute;
        display: block;
        z-index: 1;
        color: $primary;
        height: 100%;
        width: auto;
        aspect-ratio: 17/33;
        top: 0;
    }
    &:not(li:first-child):before {
        background-image: svg-arrow(nock, $primary, $primary, 2);
        background-repeat: no-repeat;
        background-size: cover;
        right: calc(100% - $border-size / 2);

    }
    &:after {
        background-image: svg-arrow(point, $primary, $primary, 2);
        background-repeat: no-repeat;
        background-size: cover;
        left: calc(100% - $border-size / 2);
        height: 100%;
        width: auto;
    }
}


@mixin breadcrumb-arrow($primary, $accent, $border-size ) {
    gap: 28px;

    > * {
        display: inline-flex;
        align-items: center;


        @include arrow($primary, $accent, $border-size);

        &:first-child {
            border-left: $border-size solid $primary;
        }

        &.selected {
            span {
                background: $accent;
                color: $primary;
            }

            &:before:not(first-child) {
                background-image: svg-arrow(nock, $accent, $primary, $border-size);
                background-repeat: no-repeat;
                background-size: cover;
            }

            &:after {
                background-image: svg-arrow(point, $accent, $primary, $border-size);
            }
        }


    }

}

@mixin wrapper-content-primary {
    border-radius: 12px;
    border: 1px solid var(--gris, #636A76);
    background: var(--anthracite, #323A49);
    box-shadow: 0 12px 40px -25px rgba(0, 0, 0, 0.50);
}


app-progression {
    @include grid-aaa-bbc;
    row-gap: 38px;
    column-gap: 23px;
    min-height: 200px;

    background-color: var(--faux_blanc);
    padding: 0 24px;
    background: image('backgrounds/cartelondresplat.png') no-repeat;
    background-size: cover;

    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color: rgb(255 255 255 / 0.7);
    }

    > * {
        z-index: 1;
    }


    ul {
        @include reset-list;
    }

    .mat-mdc-dialog-title,
    mat-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-title.mat-mdc-dialog-title {
        margin: 0 -24px;

        display: flex;
        flex-wrap: wrap !important; // pourtant le poids était plus important
        padding-bottom: 0 !important;
        margin-bottom: 7px;
        gap: 14px;

        label {
            @include chips(tertiary);
        }
        button.close {
            margin-left: auto !important;
        }

        .mat-mdc-form-field {
            margin-left: 7px;

            .mat-mdc-form-field-infix {
                padding: 0;
            }
        }

        .breadcrumb {
            @include reset-list;
            flex: 1 1 100%;
            display: flex;
            background-color: white;
            min-height: 31px;
            margin: 0 -14px 0 -24px;
            padding: 7px 30px 8px 8px;

            li {
            }

            @include breadcrumb-arrow(rgb(3, 124, 194), rgb(255, 255, 255), 2px);


        }
    }

    .mat-mdc-dialog-content, .mat-mdc-dialog-actions {
        margin-top: 38px;
        margin-bottom: 62px !important;
        padding: 20px;

        @include wrapper-content-primary;
    }

    .mat-mdc-dialog-content {
        display: flex;
        flex-direction: column;
        align-self: start;
        gap: 10px;
        margin: 0 0 0 0 !important; // marge pour le dépassement de la flèche
        overflow: visible;
        color: var(--text-contrast, #fff);

        > h3, > .title {
            align-self: flex-start;
            margin: 0 0 0 -25px; // dépassement de la flèche
            max-width: calc(100% - 25px);
            @include arrow(rgb(3, 124, 194), rgb(255, 255, 255), 2px);

        }

        section {
            background-color: $progression__dialog-background-color; // var(--gris_de_contour, #636A76);
            border: 1px solid var(--noir_5, rgba(0, 0, 0, 0.05));
            border-radius: 5px;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            h4 {
                margin: 7px 20px;

            }

            li {
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: space-between;
                min-height: 38px;
                background-color: var(--faux-blanc, #F9FBFF);;
                border: 1px solid var(--gris-de-contour, #CBD1DB);
                color: $accent-c;
                padding: 5px 7px 5px 22px;

                /* H6 Bold 12 */
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                cursor: pointer;

                .status {
                    flex: none;
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    padding-right: 4px;
                    position: relative;

                    &:after, &:before {
                        position: absolute;
                        content: "";
                        width: 1.2rem;
                        height: 1.2rem;
                        display: block;
                        border: 1px solid var(--anthracite, #323A49);
                        border-radius: 1em;
                        right: 4px;
                    }
                }

                &.done {
                    color: var(--anthracite, #323A49);
                    background-color: var(--fond-ecran, #E8EDF6);

                    .status {
                        font-weight: inherit;

                        background-color: var(--fond-ecran, #E8EDF6);
                        padding-right: 20px;

                        &:before {
                            background-color: var(--anthracite, #323A49);
                            border: 1px solid var(--anthracite, #323A49);
                            border-radius: 1em;
                            right: 4px;
                        }

                        &:after {
                            -webkit-mask: icon('check.svg') no-repeat 50% 50%;
                            mask: icon('check.svg') no-repeat 50% 50%;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-size: 1.2rem;
                            background-color: $default-font-contrast-color;
                            z-index: 50;
                            top: 50%;
                            right: 5px;
                            border: none;
                            transform: translateY(-50%);
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }

                &.next .status {
                    color: $default-font-contrast-color;
                    border-radius: 16px;
                    background: $accent-c;
                    padding: 5px 5px 6px 12px;
                    position: relative;

                    &:before {
                        display: none;
                    }

                    &:after {
                        position: relative;
                        background-color: white;
                        border-radius: 1em;
                        border: none;
                        right: 0;
                    }
                }
            }
        }
    }

    .mat-mdc-dialog-actions,
    .mat-mdc-dialog-container .mat-mdc-dialog-surface .mat-mdc-dialog-actions.mat-mdc-dialog-actions // TODO selecteur trop lourd dans default
    {
        display: flex;
        flex-direction: column !important;
        align-items: flex-start !important;

        color: var(--text-contrast, #fff);

        h3, button, a {
            width: 100%;
            max-width: 300px;
            margin: 0 auto 15px auto;
        }

        h3 {
            margin-bottom: 15px;
            align-self: stretch;
            /* H3 Bold 18 */
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 0 100px 15px 0;

        }

        button {
            flex-direction: row-reverse;
            gap: 20px;
            justify-content: space-between !important;

            span {
                word-break: break-all;
            }

            &[disabled] {
                opacity: 0.5;
                cursor: not-allowed;
            }


            position: relative;

            &:before {
                position: absolute;
                content: "";
                display: block;
                width: 85px;
                height: 92px;
                bottom: 100%;
                right: 14px;
                background: image("progression.png") no-repeat;
            }

        }

        a {
            text-decoration: underline;
            margin-bottom: 12px;
            color: var(--faux_blanc, #F9FBFF);
        }

    }

    .mat-mdc-select {
        background: white;
    }
    .spinner-wrapper {
        position: absolute;
        top: 50%;
    }
}