// --------------------------------------------------
// ABSTRACTS
// --------------------------------------------------

/**
 * The abstracts/ folder gathers all Sass tools and helpers used across the project. Every global variable, function, mixin and placeholder should be put in here. The rule of thumb for this folder is that it should not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
*/

@import "abstracts/layouts";

// --------------------------------------------------
// PAGES
// --------------------------------------------------

/**
 * If you have page-specific styles, it is better to put them in a pages/ folder, in a file named after the page. For instance, it’s not uncommon to have very specific styles for the home page hence the need for a _home.scss file in pages/.
*/

@import "pages/app-activity-editor-page";
@import "pages/app-activity-editor-panel";

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

/**
 * For smaller components, there is the components/ folder. While layout/ is macro (defining the global wireframe), components/ is more focused on widgets. It contains all kind of specific modules like a slider, a loader, a widget, and basically anything along those lines. There are usually a lot of files in components/ since the whole site/application should be mostly composed of tiny modules.
*/

@import "components/expansion-panel";
@import "components/app-activity-edition-preview";
@import "components/activity-edition-preview-qcm";
@import "components/activity-edition-preview-sort-mat";
@import "components/activity-edition-preview-text-matching";
@import "components/activity-edition-preview-true-false";
@import "components/activity-edition-short-answer";
@import "components/app-activity-edition-form";
@import "components/app-activity-edition-true-false";
@import "components/app-activity-edition-qcm-qcmu";
@import "components/app-activity-edition-matching";
@import "components/app-activity-edition-sort-mat";
@import "components/app-choose-typology-step";
@import "components/app-activity-edition-grid";
@import "components/app-activity-edition-typology-bloc";
@import "components/app-activity-edition-horizontal-selector";
@import "components/activity-editor-text-input";
@import "components/app-media-upload";
@import "components/app-activity-edition-flashcard-edit";
@import "components/app-activity-edition-add-media-step";
@import "components/app-activity-edition-flashcard-list";
@import "components/app-activity-edition-flashcard-viewer";