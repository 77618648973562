#app-feedback {
    form {
        display: flex;
        flex-direction: column;
        margin: auto;
        background-color: white;
        padding: 30px;
        height: 92vh;
        width: 70vh;
        overflow: auto;

        img {
            height: 15vh;
            margin: auto;
        }

        textarea {
            height: 6vh;
            margin-bottom: 20px;
        }

        button.mat-mdc-raised-button {
            margin: auto;
            height: 5vh;
        }
    }

    app-upload-file-generic {
        height: auto;

        form {
            height: auto;
        }
    }
}

fuse-register {
    /**
    to show info message at bottom of field
     */
    .info-mail {
        position: absolute;
        margin: auto;
        display: block;
        transform: translate(-14px, 18px);
        font-size: 12px;
    }
}

fuse-profile-about {

    #about {

        .about-content {

            .profile-box {
                overflow: hidden;
                color: $profile-box--color;
                border-radius: $profile-box--border-radius;
                box-shadow: $profile-box--box-shadow;

                header {
                    background-color: $profile-box__header--background-color !important; // Override Fuse style
                    color: $profile-box__header--color !important; // Override Fuse style

                    .title {
                        font-size: $profile-box__header--font-size;
                        font-weight: $profile-box__header--font-weight;
                    }
                }

                .mat-mdc-card-actions {
                    padding: 8px;
                    background-color: $profile-box__actions--background-color;

                    button {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                }
            }
        }
    }
}