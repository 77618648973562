mat-expansion-panel {
    @keyframes flip-horizontal {

        0% {
            transform:rotate(0) rotateX(0) !important;
        }
        100% {
            transform:rotateX(-180deg) !important;
        }
    }

    // Remove if you remove default theme accordion TODO intersprint
    &.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header .mat-icon,
    &.mat-expansion-panel.mat-expanded .mat-expansion-panel-header .mat-icon,
    .mat-expansion-panel-header .mat-icon
    {
        transform: none;
    }

    .mat-expansion-indicator {
        display: inline-block; // override default value
        justify-content: center;
        transform:rotate(0) !important; // orverride style inline
        transform:rotateX(0) translatey(-1px) !important;

        &:after { // arrow icon
            color: white;
        }

    }

    &.mat-expanded .mat-expansion-indicator  {
        transform:rotate(0) !important; // orverride style inline
        transform:rotateX(-180deg) !important;

        animation-name: flip-horizontal;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: none;

        /* shorthand
        animation: flip-horizontal 1s linear 0s 1 normal none;*/
    }


}