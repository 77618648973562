app-home-page {

    .home-page-wrapper {
        gap: 30px;
        padding: 30px;

        .resume-title,
        .discover-title {
            align-self: flex-start;
            margin: 0;
            font-weight: 700;
        }

        .container-banner-home-header {
            width: 100%;
            margin: 0;
            border-radius: $app-home-page__header--border-radius;

            .banner {
                position: $app-home-page__banner--position;
                inset: 0;
                margin: auto;
                height: $app-home-page__banner--height;
                max-height: unset;
                margin: 0;
                border-radius: inherit;
            }

            .home-header {
                position: $app-home-page__header--position;
                top: unset;
                width: auto;
                height: unset;
                max-height: unset;
                margin: 0;
                padding: $app-home-page__header--padding;
                color: unset;
                background-color: $app-home-page__header--background-color;
                background-image: $app-home-page__header--background-image;
                border-radius: inherit;

                .title {
                    color: $app-home-page__card-header--color;

                    & + .content {
                        margin-top: 15px;
                    }
                }

                .content {

                    p {
                        margin: 0;
                        color: $app-home-page__card-header--color;
                        font-size: $default-font-size;
                        line-height: 1.2;

                        & + p {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }

        .content-cards {
            width: 100%;

            &.data-cards {
                margin-top: 10px;
            }

            .mat-mdc-card {
                height: 100%;

                &.home-page__progression-card {
                    min-height: 15rem;
                }

                &.home-page__collection-card {
                    min-height: 25rem;
                }

                .mat-mdc-card-header {
                    padding: unset;
                    background-color: $primary-c;

                    .mat-mdc-card-title,
                    .mat-mdc-card-title-text {
                        color: $app-home-page__card-header--color;
                        font-size: 1.4rem;
                        font-weight: 700;

                        &:where(h2, h3) {
                            margin: 1.2rem 0;
                        }
                    }
                }

                .wrapper-content {

                    mat-card-content.mat-mdc-card-content {
                        padding: unset;
                        color: $default-font-color;
                        font-size: $default-font-size;
                        font-weight: $default-font-weight;
                        line-height: $default-line-height;
                    }
                }

                mat-card-actions {
                    min-height: unset;

                    button {
                        @extend %button--accent;
                    }
                }
            }
        }
    }
}