@use '@angular/material' as mat;
app-graph-group-management {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;


    .mat-mdc-tab-links {
        justify-content: center;

        button {
            text-transform: uppercase;
            color: #15213F;
        }
    }

    .mat-mdc-tab-nav-bar {
        border-bottom: none;

        &.mat-primary .mat-ink-bar  {
            background-color:  mat.get-color-from-palette($accent, 500);
        }
    }

    .action-button {
        padding: 5px;

        button {
            min-height: 36px;
            padding: 5px 20px;

            border-radius: 2em;
            background-color: mat.get-color-from-palette($accent, 500);

            font-weight: 700;
            color: white;
            text-transform: uppercase;

            span {
                width: 10em;
                white-space: normal;
                line-height: 1.1em;
            }
        }

    }

}