.assignment-by-steps-wrapper {
    width: 128rem;
    min-width: min-content;
    max-height: 90vh;

    .mat-mdc-dialog-surface {
        overflow-y: auto;
        border-radius: 14px;

        app-assignment-by-steps {
            border-radius: inherit;

            .assignment-by-steps {
                @include white-to-transparent-border;
                color: $secondary-c;
                border-radius: inherit;

                .assignment-by-steps__header {
                    display: flex;
                    align-items: center;
                    gap: 1.5rem;
                    padding: 1.5rem 1rem 1.5rem 2.5rem;
                    background-color: $secondary-c;

                    .assignment-by-steps__title {
                        margin: 0;
                        color: white;
                        font-size: 1.8rem;
                        line-height: 1.2;
                    }

                    .assignment-by-steps__step-container {
                        display: flex;
                        gap: 0.5rem;

                        .assignment-by-steps__step {
                            padding: 0.5rem 1rem;
                            background-color: rgba(255, 255, 255, 0.2);
                            border: 1px solid rgba(0, 0, 0, 0.10);
                            border-radius: 2px;
                            font-style: italic;
                            font-weight: 800;
                            transition: color 100ms ease-in-out,
                                        background-color 100ms ease-in-out;

                            &:first-child {
                                padding-left: 1rem;
                                border-top-left-radius: 38px;
                                border-bottom-left-radius: 38px;
                            }

                            &:last-child {
                                padding-right: 1rem;
                                border-top-right-radius: 38px;
                                border-bottom-right-radius: 38px;
                            }

                            &.assignment-by-steps__step--active {
                                color: rgba(255, 255, 255, 1);
                                background-color: $assignment-by-steps__step--background-color;
                            }
                        }
                    }

                    .assignment-by-steps__close-button {
                        @include button(
                            $type: dark,
                            $icon-full: true,
                        );
                        padding: 0.5rem 1rem; // Override property set in button.scss style sheet
                        margin-left: auto;
                    }
                }

                .assignment-by-steps__actions {
                    display: flex;
                    align-items: center;
                    gap: 1.5rem;
                    padding: 1.5rem;
                    background-color: $secondary-c;

                    .assignment-by-steps__previous-button {
                        @include button($type: dark);
                    }

                    .assignment-by-steps__next-button,
                    .assignment-by-steps__validate-button {
                        @include button();
                        margin-left: auto;
                    }

                    .assignment-by-steps__previous-button,
                    .assignment-by-steps__next-button,
                    .assignment-by-steps__validate-button {
                        padding: 1rem 1.5rem; // Override property set in button.scss style sheet
                    }
                }
            }
        }
    }
}

// --------------------------------------------------
// COMMON STYLE FOR SUBCOMPONENTS
// --------------------------------------------------

app-assignment-select-seances,
app-assignment-select-one-seance,
app-assignment-select-assignated,
app-assignment-manage-dates {

    .assignment-select-seances,
    .assignment-select-one-seance,
    .assignment-select-assignated,
    .assignment-manage-dates {
        background-color: $background-grey;

        .assignment-select-seances__breadcrumb,
        .assignment-select-one-seance__breadcrumb,
        .assignment-select-assignated__breadcrumb,
        .assignment-manage-dates__breadcrumb {
            display: flex;
            align-items: center;
            padding: 1rem 2.5rem;
            background-color: rgba($secondary-c, 0.03);
            border-bottom: 1px solid rgba($secondary-c, 0.05);
            line-height: 1.2;

            .assignment-select-seances__breadcrumb-item,
            .assignment-select-one-seance__breadcrumb-item,
            .assignment-select-assignated__breadcrumb-item,
            .assignment-manage-dates__breadcrumb-item {
                display: flex;
                align-items: center;

                .assignment-select-seances__breadcrumb-label,
                .assignment-select-one-seance__breadcrumb-label,
                .assignment-select-assignated__breadcrumb-label,
                .assignment-manage-dates__breadcrumb-label {
                    font-size: 1.6rem;
                    font-weight: 700;
                }

                .assignment-select-seances__breadcrumb-icon,
                .assignment-select-one-seance__breadcrumb-icon,
                .assignment-select-assignated__breadcrumb-icon,
                .assignment-manage-dates__breadcrumb-icon {
                    color: rgba($secondary-c, 0.3);
                }
            }
        }

        .assignment-select-seances__content,
        .assignment-select-one-seance__content,
        .assignment-select-assignated__content,
        .assignment-manage-dates__content {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            padding: 2rem;

            .assignment-select-seances__title,
            .assignment-select-one-seance__title,
            .assignment-select-assignated__title,
            .assignment-manage-dates__title {
                margin: unset;
                font-size: 1.8rem;
                font-weight: 600;
            }
        }
    }
}