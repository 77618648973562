@use '@angular/material' as mat;
.fuse-card {
    max-width: 320px;
    min-width: 320px;
    background: white;
    border-radius: 2px;
    @include mat.elevation(2);

    &.variable-width {
        min-width: 0;
    }

    &.auto-width {
        min-width: 0;
        max-width: none;
    }

    // Buttons
    .mat-mdc-button {
        min-width: 0 !important;
        padding: 0 8px !important;
    }

    // Button Toggle Group
    .mat-button-toggle-group,
    .mat-button-toggle-standalone {
        box-shadow: none !important;
    }

    // Tabs
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-mdc-tab-labels {
        justify-content: center;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mdc-tab__text-label {
        min-width: 0 !important;
    }

    // Divider
    .card-divider {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        margin: 16px;

        &.light {
            border-top-color: rgba(255, 255, 255, 0.12);
        }

        &.full-width {
            margin: 0;
        }
    }

    // Expand Area
    .card-expand-area {
        overflow: hidden;

        .card-expanded-content {
            padding: 8px 16px 16px 16px;
            line-height: 1.75;
        }
    }
}