app-wysiwyg {
    display: flex;
    width: 100%;
    .ckeditor-main-container {
        display: flex;
        flex: 1;
        .ckeditor-editor-container {
            display: flex;
            flex: 1;
            .editor-container__editor {
                display: flex;
                flex: 1;
                max-width: unset;
                ckeditor {
                    display: flex;
                    flex: 1;
                    .ck-editor {
                        display: flex;
                        flex: 1;
                        flex-direction: column;

                        &.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content,
                        &.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content.ck-rounded-corners {
                            border-top-left-radius: 0.6rem;
                            border-top-right-radius: 0.6rem;
                            .ck.ck-toolbar {
                                border-top-left-radius: 0.6rem;
                                border-top-right-radius: 0.6rem;
                            }
                        }
                        &.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
                        .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
                            border-bottom-left-radius: 0.6rem;
                            border-bottom-right-radius: 0.6rem;
                        }
                    }
                }
            }

        }
    }
}
