input,
textarea {
    @include tertiary-button-base;
    @include px-1;
    background-color: var(--white, #fff);
    color: var(--text, #006AA6);
    font-family: inherit;
    font-weight: 500;
    line-height: inherit;

    &:active {
        background-color: inherit;
    }

    &:focus {
        background-color: white;
    }
}

input {
    margin-bottom: unset;
    border: none;
    border-bottom: 4px solid #b10745;
    border-radius: 4px;
    box-shadow: none;
}

textarea {
    border-radius: 12px;
}

.textarea-wrapper {
    flex: 1;
    align-self: stretch;
    display: flex;

    textarea {
        flex: 1;

        @include media-breakpoint-up('sm') {
            min-height: 210px;
        }
    }
}