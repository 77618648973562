@use "sass:map";

app-activity-edition-matching {

    .activity-edition__btn-matching-add-match {
        @include button(primary, m);
    }

    mat-expansion-panel.mat-expansion-panel {
        background-color: $field-wrapper-hightlighter-bgcolor;

        .active & {
            background-color: lighten($field-wrapper-hightlighter-bgcolor-active, 10%);
        }

        .mat-expansion-panel-header {
            background-color: map.get($primary, 500);

            display: flex;
            padding: $radius-l $radius-l $radius-l $radius-xl;
            align-items: center;
            gap: $gap-m;


            border-top-left-radius: $radius-m;
            border-top-right-radius: $radius-m;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .mat-content {
                display: flex;
                align-items: center;
                flex-grow: 1;
            }

            $svg-path-fill-color: #6E8594;
            $svg-path-fill-color-active: #fff;
            .typology-icon svg path { fill: $svg-path-fill-color; }
            &#mat-expansion-panel-header-0 .typology-icon svg .svg-pair-0 { fill: $svg-path-fill-color-active; }
            &#mat-expansion-panel-header-1 .typology-icon svg .svg-pair-1 { fill: $svg-path-fill-color-active; }
            &#mat-expansion-panel-header-2 .typology-icon svg .svg-pair-2 { fill: $svg-path-fill-color-active; }
            &#mat-expansion-panel-header-3 .typology-icon svg .svg-pair-3 { fill: $svg-path-fill-color-active; }

            .button-trash {
                @include button('secondary', m);
                color: map_get($warn, 500);
                margin-right: $gap-m;
                mat-icon.mat-icon svg {
                    // color: map_get($warn, 500) !important;
                }
            }
            .button-move-up {
                @include button('secondary', m, $group-button-position: start);
            }
            .button-move-down {
                @include button('secondary', m, $group-button-position: end);
            }


            .mat-expansion-panel-header-title {
                color: $default-font-contrast-color;
                gap: $radius-s;
                > .mat-icon {
                    margin-right: $gap-m;
                    transform: unset;
                }

                // activity-edition-form-icon__appariement
                button {
                    margin-left: auto;
                    mat-icon.mat-icon {
                        transform: unset;
                    }
                }
            }
            .mat-expansion-indicator {
                @include button('secondary', m);
                min-width: 50px;
                min-height: 38px;
                &:after {
                    border-width: 0 3px 3px 0;
                }
            }

        }

        .mat-expansion-panel-body {
            display: grid;
            grid-template-columns: minmax(200px, 350px) 1fr;
            grid-template-rows: 1fr;

            padding: $gap-m;
            gap: $gap-m;

            app-activity-editor-text-input {
                grid-column: 2 / span 1;
                grid-row: 1;

                @include app-activity-edition-form__field;
                align-items: flex-start;
            }
            app-image-upload, .app-image-upload {
                grid-column: 1 / span 1;
                grid-row: 1;
            }
        }

    }
}