app-activity-edition-typology-bloc,
.app-activity-edition-typology-bloc // même style à l'étape suivante
{
    @include app-activity-edition-form__field;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    &:after {
        .section-app-activity-edition-typology-bloc & {
            position: absolute;
            right: 25px;
            border-style: solid;
            border-width: 0 3px 3px 0;
            content: "";
            display: inline-block;
            padding: 4px;
            transform: rotate(45deg);
            vertical-align: middle;
        }

    }

    app-activity-edition-typology-icon {
        flex: none;

        > * {
            word-break: break-all;
        }

        mat-icon.mat-icon {
            width: 5rem;
            height: auto;
            aspect-ratio: 1;

            svg {
                width: 100%;
                min-width: unset;
                height: 100%;
                min-height: unset;
            }
        }
    }

    .activity-edition-typology-bloc__content {
        display: flex;
        flex-direction: column;
        text-align: left;
        font-size: 1.4rem;

        .activity-edition-typology-bloc__title {
            margin: 0 0 0.5rem 0;
        }

        .activity-edition-typology-bloc__description {
            margin: unset;
            font-weight: 500;
        }
    }
}