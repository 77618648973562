mat-card-footer.mat-mdc-card-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0; // overide default material footer style
    @include pb-3; // pas de margin mais padding dans un contexte de flex

    &:empty {
        display: none;
    }
}