// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

// ----- COLORS

$user-review-light-red: #EB6F76 !default;
$user-review-dark-red: #C32C2C !default;
$user-review-light-orange: #F1943D !default;
$user-review-dark-orange: #B26216 !default;
$user-review-light-yellow: #EDC600 !default;
$user-review-dark-yellow: #887617 !default;
$user-review-light-green: #8AC89A !default;
$user-review-dark-green: #24883D !default;

// --------------------------------------------------
// STYLE
// --------------------------------------------------

// ----- BOTTOM SHEET WRAPPER (MOBILE) & DIALOG WRAPPER (DESKTOP)

.user-review-bottom-sheet-wrapper,
.user-review-dialog-wrapper {

    .mat-bottom-sheet-container,
    .mat-mdc-dialog-surface {
        display: contents !important; // Hide Material container to show user-review component entry and leave animations
    }
}

// ----- COMPONENT

app-user-review {
    overflow: hidden;
    max-height: 80vh;
    color: $black-c;
    background-color: $white-c;
    line-height: 1.2;

    .user-review-bottom-sheet-wrapper & { // If user-review component is inside bottom sheet wrapper (mobile)
        width: 100vw;
        border-radius: 24px 24px 0 0;
    }

    .user-review-dialog-wrapper & { // If user-review component is inside dialog wrapper (desktop)
        border-radius: 18px;
    }

    .user-review {
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        width: min-content;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        padding: 4.2rem 3.6rem 3.6rem;

        @include media-breakpoint(gt-sm) { // If the screen is greater than 960px (desktop)
            padding: 3.6rem 3.6rem 2.4rem;
        }

        .user-review__header {

            .user-review__title {
                margin: unset;
                text-align: center;
                font-size: 2rem;
                font-weight: 800;
                line-height: inherit;
            }
        }

        .user-review__content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.2rem;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 7.8rem;
                height: auto;
                aspect-ratio: 1;
                margin: unset;
                padding: 0.6rem;
                background-color: unset;
                border: 2px solid;
                border-radius: 18px;
                transition: color 100ms ease-in-out,
                            background-color 100ms ease-in-out,
                            border-color 100ms ease-in-out;

                @include media-breakpoint(gt-sm) { // If the screen is greater than 960px (desktop)
                    width: auto;
                    height: 7.8rem;
                    aspect-ratio: 3 / 2;
                }

                mat-icon {
                    flex: none;
                    width: 4.2rem;
                    min-width: unset;
                    height: auto;
                    min-height: unset;

                    svg {
                        width: 100%;
                        min-width: unset;
                        height: 100%;
                        min-height: unset;
                    }
                }

                &.user-review__very-dissatisfied-button {
                    color: $user-review-dark-red;
                    border-color: $user-review-light-red;

                    &:hover {
                        border-color: $user-review-dark-red;
                    }

                    &:active {
                        color: $white-c;
                        background-color: $user-review-dark-red;
                        border-color: $user-review-dark-red;
                    }
                }

                &.user-review__dissatisfied-button {
                    color: $user-review-dark-orange;
                    border-color: $user-review-light-orange;

                    &:hover {
                        border-color: $user-review-dark-orange;
                    }

                    &:active {
                        color: $white-c;
                        background-color: $user-review-dark-orange;
                        border-color: $user-review-dark-orange;
                    }
                }

                &.user-review__satisfied-button {
                    color: $user-review-dark-yellow;
                    border-color: $user-review-light-yellow;

                    &:hover {
                        border-color: $user-review-dark-yellow;
                    }

                    &:active {
                        color: $white-c;
                        background-color: $user-review-dark-yellow;
                        border-color: $user-review-dark-yellow;
                    }
                }

                &.user-review__very-satisfied-button {
                    color: $user-review-dark-green;
                    border-color: $user-review-light-green;

                    &:hover {
                        border-color: $user-review-dark-green;
                    }

                    &:active {
                        color: $white-c;
                        background-color: $user-review-dark-green;
                        border-color: $user-review-dark-green;
                    }
                }
            }
        }

        .user-review__footer {
            display: flex;
            justify-content: center;

            .user-review__skip-button {
                margin: unset;
                padding: 0.6rem 1.2rem;
                color: #5D5D5D;
                background-color: unset;
                border: unset;
                font-size: 1.4rem;
                font-weight: 600;
                line-height: inherit;
                transition: color 100ms ease-in-out;

                &:hover,
                &:focus,
                &:active {
                    color: inherit;
                }
            }
        }
    }
}