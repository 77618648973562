@use '@angular/material' as mat;
@use "sass:map";
@import "theme";

// --------------------------------------------------
// BUTTONS
// --------------------------------------------------

$button-padding: (
    m: 0.8rem 1.4rem,
    s: 0.6rem 1.2rem,
    mx: 1.4rem,
    sx: 1.2rem,
    my: 0.8rem,
    sy: 0.6rem,
);

$button-radius: (
    m: $radius-m,
    s: $radius-s,
);

$button-height: (
    m: unset,
    s: unset,
);

$button-icon: (
    m: 2.2rem,
    s: 1.6rem,
    m-full: 2.2rem,
    s-full: 1.6rem,
);

$button-gap: (
    m: 0.8rem,
    s: 0.6rem,
);

$button-border-width: 1px;

$button-primary: (
    background: linear-gradient(180deg, map_get($accent, 500) 0%, map_get($accent, 400) 100%),
    box-shadow: 0px 4px 10px 0px rgba($accent-c, 0.25),
    gradient-border: rgba(255, 255, 255, 0.5),
    border-radius: (
        m: $radius-s,
        s: $radius-m,
    ),
    color: var(--white, #fff),
    hover: (
        background: linear-gradient(180deg, map_get($md-accent, 500) 0%, map_get($md-accent, 600) 100%),
        box-shadow: (0px 4px 10px 0px rgba($accent-c, 0.35),
        0px 4px 0px 100px rgba(255, 255, 255, 0.10) inset),
        gradient-border: rgba(255, 255, 255, 0.5),
        color: var(--white, #fff),
    ),
    pressed: (
        background: (
            linear-gradient(180deg, map_get($md-accent, 600) 0%, map_get($md-accent, 700) 100%),
        ),
        color: var(--white, #fff),
    ),
    disabled: (
        background: #D1D6DF,
        color: #989CA4,
    ),
);

$button-secondary: (
    background: linear-gradient(180deg, map_get($md-secondary, 400) 0%, map_get($md-secondary, 500) 100%),
    box-shadow: 0px 4px 10px 0px rgba(51, 58, 73, 0.10),
    gradient-border: rgba(255, 255, 255, 0.5),
    border-radius: (
        m: $radius-m,
        s: $radius-s,
    ),
    color: white,
    hover: (
        background: linear-gradient(180deg, map_get($md-secondary, 500) 0%, map_get($md-secondary, 600) 100%) !important,
        box-shadow: (0px 4px 0px 100px rgba(255, 255, 255, 0.25) inset, 0px 4px 10px 0px rgba(51, 58, 73, 0.15)),
        gradient-border: rgba(255, 255, 255, 0.5),
        color: white !important,
    ),
    pressed: (
        background: linear-gradient(180deg, map_get($md-secondary, A200) 0%, map_get($md-secondary, A400) 100%) !important,
        color: white !important,
    ),
    disabled: (
        background: #D1D6DF,
        color: #989CA4,
    ),
);

$button-secondary--deprecated: ( // Secondary button colors were edited in a previous commit (commit hash: 353ea2fbdbc7b74bc53505c339f90301ce252926) but this deprecated version is still needed in app-activity-search-engine-dialog.scss to avoid style regression
    background: linear-gradient(180deg, #fff 0%, #ecebeb 100%),
    box-shadow: 0px 4px 10px 0px rgba(51, 58, 73, 0.10),
    gradient-border: rgba(255, 255, 255, 0.5),
    border-radius: (
        m: $radius-m,
        s: $radius-s,
    ),
    color: $accent-c,
    hover: (
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.90) 100%) !important,
        box-shadow: (0px 4px 0px 100px rgba(255, 255, 255, 0.25) inset, 0px 4px 10px 0px rgba(51, 58, 73, 0.15)),
        gradient-border: rgba(255, 255, 255, 0.5),
        color: map_get($md-accent, 600) !important,
    ),
    pressed: (
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.90) 100%) !important,
        color: $accent-c !important,
    ),
    disabled: (
        background: #D1D6DF,
        color: #989CA4,
    ),
);

$button-tertiary: (
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.90) 100%),
    box-shadow: 0px 4px 10px 0px rgba($secondary-c, 0.1),
    gradient-border: rgba(255, 255, 255, 0.5),
    border-radius: (
        m: $radius-m,
        s: $radius-s,
    ),
    color: $default-font-color,
    hover: (
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.90) 100%),
        box-shadow: (0px 4px 0px 100px rgba(255, 255, 255, 0.25) inset,
        0px 4px 10px 0px rgba($secondary-c, 0.15)),
        gradient-border: rgba(255, 255, 255, 0.5),
        color: rgba($secondary-c, 0.8),
    ),
    pressed: (
        background: rgba($secondary-c, 0.15),
        color: $default-font-color,
    ),
    disabled: (
        background: #D1D6DF,
        color: #989CA4,
    ),
);

$button-quaternary: (
    background: linear-gradient(180deg, map_get($quaternary, 500) 0%, map_get($quaternary, 400) 100%),
    box-shadow: 0px 4px 10px 0px $quaternary-c,
    gradient-border: rgba(255, 255, 255, 0.5),
    border-radius: (
        m: $radius-m,
        s: $radius-s,
    ),
    color: var(--white, #fff),
    hover: (
        background: linear-gradient(180deg, map_get($md-accent, 500) 0%, map_get($md-accent, 600) 100%),
        box-shadow: (0px 4px 0px 100px rgba(255, 255, 255, 0.10) inset, 0px 4px 10px 0px rgba(224, 74, 63, 0.35)),
        gradient-border: rgba(255, 255, 255, 0.5),
        color: var(--white, #fff),
    ),
    pressed: (
        background: (
            linear-gradient(180deg, map_get($md-accent, 600) 0%, map_get($md-accent, 700) 100%),
        ),
        color: var(--white, #fff),
    ),
    disabled: (
        background: #D1D6DF,
        color: #989CA4,
    ),
);

$button-dark: (
    background: #323A49,
    box-shadow: (0px -4px 10px 0px rgba(255, 255, 255, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)),
    gradient-border: rgba(255, 255, 255, 0.3),
    border-radius: (
        m: $radius-m,
        s: $radius-s,
    ),
    color: var(--white, #fff),
    hover: (
        background: #000,
        box-shadow: (0px -4px 10px 0px rgba(255, 255, 255, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)),
        gradient-border: rgba(255, 255, 255, 0.3),
        color: var(--white, #fff),
    ),
    pressed: (
        background: rgba(0, 0, 0, 0.10),
        color: var(--white, #fff),
    ),
    disabled: (
        background: rgba(255, 255, 255, 0.03),
        color: #4B525F,
    ),
);

$button-compass: (
    background: purple,
    border-radius: (
        m: $radius-m,
        s: $radius-s,
    ),
    color: var(--white, #fff),
    hover: (
        background: darken(purple, 10%),
    ),
    pressed: (
        background: darken(purple, 50%),
    ),
    disabled: (
        background: #D1D6DF,
        color: #989CA4,
    ),
    option: (
        flat: true,
    )
);

$button-type: (
    primary: $button-primary,
    secondary: $button-secondary,
    secondary--deprecated: $button-secondary--deprecated,
    tertiary: $button-tertiary,
    quaternary: $button-quaternary,
    dark: $button-dark,
    compass: $button-compass
);