// --------------------------------------------------
// ACCESSIBLE HIDING (https://a11y-guidelines.orange.com/en/articles/accessible-hiding/)
// --------------------------------------------------

@mixin visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.visually-hidden { // .cdk-visually-hidden possible aussi
    @include visually-hidden;
}





// --------------------------------------------------
// FOCUS-VISIBLE
// --------------------------------------------------

@mixin a11y-focus-visible {
    outline: 2px auto Highlight !important; // Firefox based browser default outline style and color (accessibility best practice)
    outline: 2px auto -webkit-focus-ring-color !important; // Chrome based browser default outline style and color (accessibility best practice)
}

// était dans base.scss
*:focus-visible:not(input),
.cdk-keyboard-focused { // Angular focus state related class
    @include a11y-focus-visible;
}
a:focus-visible:not(.nav-link) {
    outline-offset: 0.4em;
}


// était dans form-fields.scss
.mat-mdc-option:not(.mat-mdc-option-disabled) {

    &.mat-mdc-option-active {
        outline: 2px solid black;
        outline-offset: -2px;
    }

    &.mdc-list-item--selected {
        color: black !important;
    }
}

#login,
#register {

    .mat-mdc-form-field {

        &.mat-focused {

            .mat-mdc-form-field-flex {
                @include a11y-focus-visible;
                
                .mat-mdc-select {
                    outline: none !important;
                }
            }
        }
    }
}