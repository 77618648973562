// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

$memory__button-ratio: calc(16 / 9) !default;
$memory__min-cell-width: max(10rem, 10vw) !default;
$memory__max-cell-width: 32.5rem !default;
$memory__min-cell-height: calc($memory__min-cell-width / $memory__button-ratio) !default;
$memory__max-cell-height: calc($memory__max-cell-width / $memory__button-ratio) !default;

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-memory-find-zone {
    @include g-3;
    flex: 1;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, minmax($memory__min-cell-width, $memory__max-cell-width));
    grid-auto-rows: minmax($memory__min-cell-height, $memory__max-cell-height);
    justify-content: center;
    align-content: center;
    max-width: 100%;
    max-height: calc(100vh - 40rem);
    margin: unset !important;
    padding: unset;

    @supports selector(:has(*)) { // If browser supports :has() selector
        flex: none; // Override previous flex declaration

        @for $i from 4 through 8 { // Calculate host element ratio based on the number of answers

            &:has(> button:nth-of-type(#{$i}):last-of-type) {
                aspect-ratio: calc(2 / (($i / 2) / $memory__button-ratio));
            }
        }
    }

    @media (orientation: landscape) {
        grid-template-columns: unset;
        grid-auto-columns: minmax($memory__min-cell-width, $memory__max-cell-width);
        grid-template-rows: repeat(2, minmax($memory__min-cell-height, $memory__max-cell-height));
        grid-auto-rows: unset;

        @supports selector(:has(*)) { // If browser supports :has() selector

            @for $i from 4 through 8 { // Calculate host element ratio based on the number of answers

                &:has(> button:nth-of-type(#{$i}):last-of-type) {
                    aspect-ratio: calc(($i / 2) / (2 / $memory__button-ratio));
                }
            }
        }
    }

    .memory-find-zone__button {
        width: auto;
        min-width: 0;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        aspect-ratio: $memory__button-ratio;
        margin: unset;
        padding: unset;

        @media (orientation: landscape) {
            width: 100%;
            height: auto;
        }

        @for $i from 1 through 8 { // Enforce grid layout based on index of buttons in the child list of their parent

            &:nth-of-type(#{$i}):not(:nth-last-of-type(-n + #{calc($i - 1)})) { // First column or first row (depending on @media orientation)
                grid-column-start: 1;
                justify-self: end;

                @media (orientation: landscape) {
                    grid-column-start: unset;
                    grid-row-start: 1;
                    justify-self: unset;
                    align-self: end;
                }
            }

            &:nth-of-type(#{$i}):nth-last-of-type(-n + #{calc($i - 1)}) { // Second column or second row (depending on @media orientation)
                grid-column-start: 2;
                justify-self: start;

                @media (orientation: landscape) {
                    grid-column-start: unset;
                    grid-row-start: 2;
                    justify-self: unset;
                    align-self: start;
                }
            }
        }

        .memory-find-zone__button-image {}

        .memory-find-zone__button-title {
            position: absolute;
            z-index: 3;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            justify-content: center;
            padding: $spacing-1 $spacing-2;
            background-color: var(--primaryborder, #4B001C);
            border-top: 2px solid var(--primarybackground, #b10745);
            border-bottom-right-radius: 7px;
            border-bottom-left-radius: 7px;
        }

        &.currently-correct,
        &.was-correct {

            .memory-find-zone__button-title {
                background-color: var(--goodanswerbackground, #027F77);
                border-top: 2px solid var(--goodanswerstroke, #009E94);
            }
        }

        &.memory-find-zone__button--flipped {

            &:after {
                content: "?";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: var(--white, #FFF);
                -webkit-text-stroke: 2px var(--primaryborder, #4B001C); // Property prefixed with -webkit but supported by all modern browsers
                font-size: #{set-fluid-scale(60px, 100px)};
                font-weight: 500;
                line-height: 1;
            }

            &:nth-of-type(odd):nth-of-type(3) {

                &:after {
                    transform: translate(-50%, -50%);
                }
            }

            &:nth-of-type(odd):nth-of-type(5) {

                &:after {
                    transform: translate(-50%, -50%) rotate(25deg) scale(0.8);
                }
            }

            &:nth-of-type(odd):not(:nth-of-type(3), :nth-of-type(5)) {

                &:after {
                    transform: translate(-50%, -50%) rotate(25deg);
                }
            }

            &:nth-of-type(even):nth-of-type(2) {

                &:after {
                    transform: translate(-50%, -50%) rotate(-25deg) scale(0.8);
                }
            }

            &:nth-of-type(even):nth-of-type(6) {

                &:after {
                    transform: translate(-50%, -50%) scale(0.8);
                }
            }

            &:nth-of-type(even):not(:nth-of-type(2), :nth-of-type(6)) {

                &:after {
                    transform: translate(-50%, -50%) rotate(-25deg);
                }
            }
        }
    }

    .memory-find-zone__audio {
        @include visually-hidden;
    }
}