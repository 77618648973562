@use "sass:map";

$radio-width: 27px;
$radio-height: 27px;
$icon-size-in-field: 50px;
$editor-form-field-input-unchecked-background: #DBEAF5;
$editor-form-field-input-checked-color: #02B86B;
$editor-form-field-input-checked-background: white;

$editor-thumbs-min-width: 150px !default;
$editor-thumbs-max-width: 300px !default;

@mixin default-typology-preview-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    gap: 1.2rem;

    button {
        @include answer; // Anwser mixin from Activities module
        @include secondary-button; // Button mixin from Activities module
        justify-content: center;
        gap: 0.6rem;
        min-width: min-content;
        margin-left: unset;
        padding: 0.6rem;
        font-size: set-fluid-scale(12px, 14px);
        pointer-events: none;

        span {
            overflow-y: auto;
            overflow-x: hidden;
            overflow-wrap: anywhere; // Prevent unbreakable string overflow
        }

        &[class*="image-button"] { // Target every button with a class attribute containing "image-button"
            padding: unset;

            img {
                width: 100%;
                height: auto;
                max-height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }
}

// --------------------------------------------------
// ANSWER FIELD DEFAULT STYLE
// --------------------------------------------------
@mixin app-activity-edition-form__field {
    display: flex;
    background: white;
    border-radius: $radius-s;
    padding: 1rem;
    overflow: hidden;
    input {
        // Style
        font-size: 1.6rem;
        font-weight: 700;
    }
}

@mixin radio-in-button {
    span {
        font-size: 1.6rem;
        font-weight: 700;
        b {
            text-transform: uppercase;
            font-size: 1.8rem;
            font-weight: 700;
        }
    }
    &.active span {
        color: #02B86B;
    }
    input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: $editor-form-field-input-unchecked-background;
        margin: 0;
        // Style
        font: inherit;
        color: currentColor;
        width: $radio-width;
        height: $radio-height;
        border-radius: 50%;
        transform: translateY(0.075em);

        display: grid;
        place-content: center;

        &::before {
            content: "";
            width: $radio-width;
            height: $radio-height;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 0 0 0 0.7rem $editor-form-field-input-checked-color; // #02B86B;
            background-color: $editor-form-field-input-checked-background; // white;
        }
        &:checked {
            border: none;
            color: $editor-form-field-input-checked-color;
            &::before {
                transform: scale(1);
            }
        }
    }
}

// --------------------------------------------------
// COMMON FIELD WRAPPER STYLES
// --------------------------------------------------
@mixin common-field-wrapper-styles {
    @include app-activity-edition-form__field;
    padding: 1rem;
    overflow: auto;
    flex-wrap: wrap;

    app-activity-editor-text-input {
        flex: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 170px;
        input {
            min-width: 100px;
        }
    }

    > button {
        flex: 1;
        overflow: hidden;
        margin: 0;

        &.activity-edition__btn-onAnswerRemove {
            //@include button("tertiary", $size: m, $text-hide: true, $flat-button: true, $icon-full: true);
            font-size: 1.2rem;
            min-width: 4.5rem;
            mat-icon.mat-icon {
                color: map.get($accent, 500) !important;
            }
        }
    }
}

// --------------------------------------------------
// ANSWER FIELD TYPE TEXT
// --------------------------------------------------
@mixin field-wrapper-answer-type-text {
    @include common-field-wrapper-styles;
    display: grid;
    grid-template-columns: 1fr auto minmax($icon-size-in-field, auto);


}

// --------------------------------------------------
// ANSWER FIELD TYPE IMAGE
// --------------------------------------------------
@mixin field-wrapper-answer-type-image-and-audio {
    @include common-field-wrapper-styles;
    display: grid;
    gap: 5px;
    grid-template-columns: minmax($editor-thumbs-min-width, 1fr) 1fr;
    grid-template-rows: 1fr minmax(55px, 0.5fr);


    app-image-upload, app-audio-upload,
    .app-image-upload, .app-audio-upload {
        grid-column: 1 / span 1;
        grid-row: 1 / span 2;
        margin-top: auto;
    }
    app-activity-editor-text-input {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
    }
    .activity-edition-form__btn-onSelect {
        grid-column: 2 / span 1;
        grid-row-end: -1;
    }

    .activity-edition-form__btn-onSelect,
    .activity-edition__btn-onAnswerRemove {
        margin: 0 !important;
        width: 100%;
        height: 100%;
        max-width: unset;
        min-width: unset;
    }

    @supports selector(:has(+ *)) { // Check browser support for :has() pseudo-class

        &:has(>.activity-edition__btn-onAnswerRemove) {
            grid-template-columns: minmax(50%, 1fr) 1fr $icon-size-in-field;
            grid-template-rows: 1fr minmax($icon-size-in-field, 0.5fr);

            app-activity-editor-text-input {
                grid-column: 2 / span 2;
                grid-row: 1 / span 1;
            }
            button.activity-edition-form__btn-onSelect {
                grid-column: 2 / span 1;
                grid-row: 2 / span 1;
            }
            .activity-edition__btn-onAnswerRemove {
                grid-column: 3 / span 1;
                grid-row: 2 / span 1;
            }
        }
    }

}

// --------------------------------------------------
// ANSWER FIELD TYPE FLASHCARD
// --------------------------------------------------
@mixin field-wrapper-answer-type-flashcard {
    @include common-field-wrapper-styles;
    display: grid;
    gap: 5px;
    // grid-template-columns: minmax(50%, 2fr) 1fr;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: minmax(45px, 0.4fr) 1fr minmax(45px, 0.4fr);

    .activity-edition-flashcard-list_button-toolbar {
        grid-column: 1 / span 2;
        display: flex;
        gap: $gap-m;

        &:empty {
            display: none;
        }
        > button {
            min-height: 44px;
            align-self: center;
            margin-left: 0;

            &:first-child {
                margin-left: auto;
            }

        }
    }

    app-activity-edition-flashcard-viewer {
        grid-row: 2 / span 2;
    }
    .app-image-upload, .app-media-upload {
        flex: 1;
    }

    app-activity-editor-text-input {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
    }
    .activity-edition-form__btn-onSelect {
        grid-column: 2 / span 1;
        grid-row: 3 / span 1;
    }


    .activity-edition__btn-onAnswerRemove, .activity-edition-form__btn-onFlashcardRemove {
        mat-icon.mat-icon {
            color: map.get($accent, 500) !important;
        }
    }

    @supports selector(:has(+ *)) { // Check browser support for :has() pseudo-class

        &:has(>.activity-edition__btn-onAnswerRemove) {
            grid-template-columns: minmax($editor-thumbs-min-width, 300px) 1fr $icon-size-in-field;
            grid-template-rows: 1fr minmax($icon-size-in-field, 0.5fr);

            app-activity-editor-text-input {
                grid-column: 2 / span 2;
                grid-row: 1 / span 1;
            }
            button.activity-edition-form__btn-onSelect {
                grid-column: 2 / span 1;
                grid-row: 2 / span 1;
            }
            .activity-edition__btn-onAnswerRemove {
                grid-column: 3 / span 1;
                grid-row: 2 / span 1;
            }
        }
    }

}
