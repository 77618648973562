app-activity-edition-preview-text-matching {
    @include default-typology-preview-layout;

    .activity-edition-preview-text-matching__answer-button {}

    .activity-edition-preview-text-matching__media-button-placeholder {

        .activity-edition-preview__media-icon {
            flex: none;
            width: auto;
            min-width: unset;
            height: 100%;
            min-height: unset;
            aspect-ratio: 1;

            svg {
                min-width: unset;
                min-height: unset;
            }
        }
    }
}