vertical-layout-1 #main > .container > .container > .container content > app-bd-content-editor > .page-layout > .content {
    padding: 0 !important;
}

app-bd-content-editor {

    mat-spinner {
        margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the page
    }

    .content-editor {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        place-content: stretch flex-start;
        align-items: stretch;

        .bd-header {
            font-style: normal;
            font-size: 20px;
            line-height: 24px;
            padding-left: 21px;
            padding-top: 14px;

            button.mat-mdc-mini-fab.mat-accent {
                float: right;
            }
        }

        .bd-content {
            height: 100%;
            flex: 1 1 auto;

            iframe {
                border : 0;
                height: 100%;
            }
        }
    }
}