app-true-false {

    .activities-card {

        .mat-mdc-card-content {

            .available-answers {
                grid-template-rows: 1fr;
            }
        }
    }
}