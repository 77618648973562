
%star_on {
    background: transparent icon('star_on.svg') no-repeat center center;
}

%star_off {
    background: transparent icon('star_off.svg') no-repeat center center;
}

%radio_on {
    background: transparent icon('radio_on.svg') no-repeat center center;
}

%radio_off {
    background: transparent icon('radio_off.svg') no-repeat center center;
}

%question_text {
    font-weight: bold;
    color: #3A4354;
    font-size: 16px;
    padding: 0;
    margin: 0 0 10px 0;
}

.feedback-dialog { // overlay
    display: flex;
    flex-direction: column;

    mat-dialog-container .mat-mdc-dialog-surface {
        display: flex;
        flex-direction: column;

        app-feedback-dialog, app-feedback-dialog-by-steps { // popup
            display: flex;
            flex-direction: column;
            flex: 1;

            .mat-mdc-dialog-title {
                flex: none;
                margin-bottom: 0;
            }

            .mat-mdc-dialog-content {
                display: flex;
                justify-content: flex-start;
                padding: 0;

                form {
                    flex: 1;
                    display: flex;
                    justify-content: space-around;
                    padding-top: 20px;

                    > div {
                        padding: 0 20px 20px 20px;
                    }
                }
            }
        }
    }
}

app-feedback-dialog, app-feedback-dialog-by-steps {
    mat-form-field {
        width: 100%;
    }

    .mat-mdc-form-field-infix {
        width: 100%;
    }
}

.mat-mdc-dialog-content {
    div {
        p {
            @extend %question_text;
        }
    }

    .rating {
        display: flex;
        width: 100%;
        justify-content: center;
        overflow: hidden;
        flex-direction: row-reverse;
        position: relative;

        > input {
            display: none;

            &:checked ~ label,
            &:checked ~ label ~ label {
                @extend %star_on;
            }

            &:not(:checked) ~ label:hover,
            &:not(:checked) ~ label:hover ~ label {
                @extend %star_on;
            }
        }

        > label {
            cursor: pointer;
            width: 40px;
            height: 40px;
            margin-top: auto;
            @extend %star_off;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 76%;
            transition: 0.3s;
        }

        .rating-0 {
            filter: grayscale(100%);
        }
    }

    .slider-container {
        width: 50%;
        margin: 0 auto;

        mat-slider {
            width: 100%;
            height: 28px;
        }

        ol {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: -1rem;
            list-style: none;
            padding: 0;
            margin-bottom: 0;

            li {
                position: relative;
                padding-top: 10px;

                &::before {
                    content: '';
                    height: 10px;
                    width: 2px;
                    display: block;
                    background: $primary-c;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                    position: absolute;
                }
            }
        }
    }

    fieldset {
        border: none;
        display: flex;
        justify-content: center;
        column-gap: 1.5rem;

        legend {
            @extend %question_text;

            display: flex;
            flex-direction: column;
            width: 100%;

            pre {
                align-self: center;
                padding: 0 10px;
                width: 450px;
                border: 1px solid black;
            }
        }

        label {
            color: #3A4354;
        }

        > div {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        input[type="radio"] {
            position: absolute;
            opacity: 0;
        }

        label {
            padding-top: 25px;
            text-align: center;
        }

        label:before, label:after {
            content: '';
            position: absolute;
            display: block;
            width: 20px;
            height: 20px;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        label:after {
            @extend %radio_off;
            z-index: 1;
        }

        label:before {
            // @extend %radio_on;
        }

        input:checked + label:before, label:hover:before, label:focus:before {
            @extend %radio_on;
            z-index: 2;
        }
    }

    .mat-mdc-dialog-actions {
        padding-top: 0;
    }

}