app-activity-edition-grid {
    display: grid;
    @include grid(
        $xs-col: 1,
        $xs-row: 1,
        $sm-col: 1,
        $sm-row: 1,
        $lg-col: 2,
        $lg-row: 1,
        $xl-col: 2,
        $xl-row: 1,
        $gap: 10);

    button {
        display: flex;
        margin: unset;
        padding: unset;
        background-color: $white-c;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
    }
}