
// --------------------------------------------------
// BASE
// --------------------------------------------------

@import "base/index"; // pour new css activities

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

@import "components/logo";
@import "components/nps";
@import "components/user-review";

// --------------------------------------------------
// LAYOUT
// --------------------------------------------------

@import "layout/cards";
@import "layout/page-layouts";
@import "layout/navbar-vertical.component";

// --------------------------------------------------
// PAGES
// --------------------------------------------------

@import "pages/lessons-tab";

// --------------------------------------------------
// SHARED
// --------------------------------------------------

@import "shared_core/index";
@import "shared_modules/index";

// --------------------------------------------------
// MODULES
// --------------------------------------------------

// Activities
@import "../../@modules/activities/scss/main"; // Style sheets used by both the platform and the app
#lessons {
    @import "layout/activities/app-generic-stepper";
}
@import "layout/activities/app-read-pdf";
@import "layout/activities/app-tool";
@import "pages/fuse-app-lessons-tab";

// Lesson-edition
@import "../../@modules/lesson-edition/scss/main";

// Assignation
@import "../../@modules/assignation/scss/main";

// BD Tool
@import "../../@modules/bdtool/scss/main";