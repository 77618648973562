mat-chip,
mat-chip-option,
mat-chip-row {
    
    mat-icon + span,
    span + mat-icon {
      margin-right: 0;
      margin-left: 5px;
    }

    [svgicon="cross"]{
        background-color: rgba(0, 0, 0, 0.87);
        border-radius: 50%;
        transform: scale(0.8);
        display: flex;
        svg{
            transform: scale(0.9);
            path{
                fill: white;
            }
        }
    }

}