app-activity-edition-preview-true-false {
    @include default-typology-preview-layout;

    .activity-edition-preview-true-false__true-button,
    .activity-edition-preview-true-false__false-button {

        .activity-edition-preview-true-false__true-icon,
        .activity-edition-preview-true-false__false-icon {
            flex: initial;
            width: auto;
            min-width: unset;
            height: 3.2rem;
            min-height: unset;
            aspect-ratio: 1;

            svg {
                min-width: unset;
                min-height: unset;
            }
        }
    }
}