@use '@angular/material' as mat;

@mixin reset-card { // mat-card
    margin: 0;
    padding: 0;

    &:not([class*=mat-elevation-z]){
        box-shadow: inherit;
    }

    .mat-mdc-card-avatar {
        height: inherit;
        width: inherit;
        border-radius: inherit;
        flex-shrink: inherit;
        object-fit: inherit;
    }

    .mat-mdc-card-header-text {

    }

    .mat-mdc-card-header .mat-mdc-card-title {
        margin-bottom: 0;
    }
}

@mixin card--max-width {
    max-width: 800px;
}

@mixin mat-card { // modèle général
    @include reset-card;
    flex: 1;
    @include r-2;

    mat-card-header {
        // Layout
        display: flex;
        min-height: 50px;
        @include rt-2;
        // Style

        .mat-mdc-card-header-text {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    mat-card-content {
        // Layout
        @include p-2;
        @include pb-3;
        @include rb-2;
    }
}

@mixin fake-card {
    display: flex;
    & > div { // role="button"
        display: flex;
        flex: 1;
        & > .mat-mdc-card {
            flex: 1;
        }
    }
}

// Figma CARD QUATERNAIRE
/* ... */
@mixin card--quaternaire {
    @include mat-card;
    &:not([class*=mat-elevation-z]){ // compense l'utilisation hors HTML
        @include mat.elevation(5);
    }

    mat-card-header {
        // Layout
        @include p-2;
        // Style
        color: var(--white, #fff);
        background: var(--quaternaireborder, #006AA6);
    }
    mat-card-content {
        // Style
        background: var(--quaternairebackground,#002940);
    }
}