@import "balloon-css/src/balloon";

app-agenda, app-agenda-weekly {
    table {
        padding: initial;
        width: initial;

        &.mat-mdc-table {
            border-collapse: separate;
            border-spacing: initial !important;
        }

        th.mat-mdc-header-cell, td.mat-mdc-cell {
            border: initial;
        }

        th,
        th.mat-mdc-header-cell {
            border-top: initial;
            border-bottom: initial;

            &:first-child {
                border-left: initial;
            }

            &:last-child {
                border-right: initial;
            }
        }

        tr,
        tr.mat-mdc-row {
            box-shadow: none;
            height: initial;
            overflow: initial;

            &.mat-mdc-header-row {
                height: initial;
            }
        }

        tbody:before {
            content: none;
        }
    }

    full-calendar {
        .fc-header-toolbar {
            div {
                display: flex;

                > .fc-button {
                    background: none;
                    border: none;
                    color: #000;
                    box-shadow: none;
                    outline: none;
                }
            }

        }

        .fc-head-container {
            border: none;
            padding-bottom: 10px;

            .fc-day-header {
                text-transform: capitalize;
                border: none;
            }
        }

        .fc-day-grid-container {
            overflow: visible !important;
        }



        .fc-event {
            border: none;
            height: 28px;
            border-radius: 10px !important;
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #fff;
            padding: 0 10px !important;
            cursor: pointer;
            position: relative;
            --balloon-color: #6A6A6A;

            &:hover {
                text-decoration: none !important;

                a {
                    text-decoration: none !important;
                }
            }

            &.trainer {
                background-color: #C94500;

                &:hover {
                }
            }

            &.manager {
                background-color: #ABABAB;

                &:hover {
                }
            }

            &.learner {
                background-color: #E88224;

                &:hover {
                }
            }

            &.homework {
                background-color: #24B7C7;
            }

            &.assessment {
                background-color: #006D86;
            }

            .fc-content {
                display: flex;
                flex: 1;

                .fc-event-title {
                    text-overflow: ellipsis;
                    width: 100px;
                    overflow: hidden;
                }
            }

            .fc-daygrid-event-dot {
                display: none;
            }
        }


        .fc-day {
            position: relative;

            &.fc-sat,
            &.fc-sun {
                background-color: #F6F6F6;
                color: #D3D3D3;
            }

            &.fc-today {
                background-color: #F1F1F1;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                z-index: 999;
            }
        }

        .fc-day-top {

            .fc-day-number {
                color: #A6A6A6;
                font-size: 18px;
                margin-right: 10px;
            }

            &.fc-today {

                .fc-day-number {
                    color: #838383;
                    font-size: 22px;
                }
            }
        }

        .fc-content-skeleton {
            padding-bottom: 10px;
            height: calc(100% - 10px);

            table {
                // To center events vertically
                height: 100%;

                tbody {
                    min-height: 100%;
                    height: 100px;
                    width: 100%;

                    tr {
                        height: 0;
                    }
                }
            }
        }

        .fc-dayGridWeek-view {
            .fc-content-skeleton {
                padding-top: 1em;
            }
        }
    }

    // default spacing between fields in agenda for member component
    app-members-filter {
        .mat-mdc-form-field {
            margin-right: 16px;
            max-width: 300px;
        }
    }
}