@use "sass:map";

@mixin grid-fields-styles {
    counter-reset: sortMat;

    section.typologies-fields & {
        display: grid;
        justify-content: center;
        align-items: center;
        gap: $gap-m;
    }
}

@mixin field-wrapper-styles {
    grid-column: 2 / span 1;
    display: flex;
    align-items: center;
    border-radius: $radius-s;
    padding: 1rem;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
    gap: $gap-m;
    overflow: visible;
    position: relative;

    &::after {
        content: counter(sortMat);
        counter-increment: sortMat;
        position: absolute;
        top: 50%;
        left: -50px;
        transform: translateY(-50%);
        height: 2.8rem;
        aspect-ratio: 1;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        color: black;
        border-radius: $radius-rounded;
        border: 2px solid black;
        font-size: 18px;
        font-weight: 900;
    }
}

app-activity-edition-sort-mat {
    .activity-edition__btn-icon__up {
        margin-right: $gap-m * -1;
        @include button(tertiary, $group-button-position: start);
    }

    .activity-edition__btn-icon__down {
        @include button(tertiary, $group-button-position: end);
    }

    > .grid-fields {
        @include grid-fields-styles;

        section.typologies-fields & {
            grid-template-columns: repeat(1, 50px 1fr);
            grid-template-rows: minmax(0, 1fr);
        }

        .field-wrapper {
            flex: 1;
            @include field-wrapper-styles;

            app-image-upload, app-audio-upload,
            .app-image-upload, .app-audio-upload {
                flex: 1;
            }

            audio {
                position: absolute;
                bottom: $gap-m;
                left: $gap-m;
            }

            button {
                margin-left: unset;
            }

            button mat-icon.mat-icon[svgicon="delete"] {
                color: map.get($warn, 500) !important;
            }
        }

        .activity-edition-sort-mat__undo-button {
            grid-column: 2 / span 1;
            justify-self: end;
        }
    }

    app-activity-edition-flashcard-list {
        > .grid-fields {
            @include grid-fields-styles;

            section.typologies-fields & {
                grid-template-columns: repeat(2, 50px 1fr);
                grid-template-rows: minmax(0, 1fr);
                @include media-breakpoint-down('lg') {
                    grid-template-columns: 50px 1fr;
                }
            }

            .activity-edition-flashcard-list_viewer-wrapper {
                @include field-wrapper-styles;
                flex: 1;

                &:nth-child(even) {
                    grid-column: 4 / span 1;
                }

                &:nth-child(odd) {
                    grid-column: 2 / span 1;
                }
                @include media-breakpoint-down('lg') {
                    &:nth-child(even), &:nth-child(odd) {
                        grid-column: 2 / span 1;
                    }
                }
            }
        }
    }
}
