// --------------------------------------------------
// ANIMATIONS
// --------------------------------------------------

@keyframes shift-danger-color {
    0% {
        color: $activity-editor__available-characters--danger--start-color;
    }
    100% {
        color: $activity-editor__available-characters--danger--end-color;
    }
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-activity-editor-text-input {
    @include app-activity-edition-form__field;
    gap: 1rem;

    .app-wysiwyg-wrapper {
        display: flex;
        position: relative;
        margin: -1rem;
        width: calc(100% + 2rem);



        .activity-editor-text-input__available-characters {
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
    }


    .activity-editor-text-input__icon {}

    .activity-editor-text-input__user-input {
        flex: 1;
    }

    .activity-editor-text-input__available-characters {
        font-weight: 500;

        &.activity-editor-text-input__available-characters--success {
            color: $activity-editor__available-characters--success--color;
        }

        &.activity-editor-text-input__available-characters--warning {
            color: $activity-editor__available-characters--warning--color;
        }

        &.activity-editor-text-input__available-characters--danger {
            animation: shift-danger-color 1000ms ease-in-out forwards;
        }
    }
}