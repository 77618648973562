app-assignment-manage-dates {

    .assignment-manage-dates {

        .assignment-manage-dates__content {

            .assignment-manage-dates__fieldset-container {
                @include transparent-to-white-border;
                display: flex;
                flex-direction: column;
                gap: 1px;
                padding: 1rem;
                background-color: rgba($secondary-c, 0.15);
                border-radius: 12px;

                .assignment-manage-dates__fieldset-wrapper {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: auto 1fr; // repeat(2, auto) would be more appropriate but it causes the layout to break in Safari browser
                    gap: 1rem;
                    padding: 1.5rem;
                    background-color: $off-white;

                    &:first-child {
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }

                    .assignment-manage-dates__fieldset {
                        display: contents; // Remove fieldset element rendered box (for styling reasons) while keeping its tag (for accessibility reasons)

                        .assignment-manage-dates__fieldset-legend {
                            grid-column: 1 /span 2;
                            grid-row: 1 / span 1;
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                            font-weight: 600;

                            .assignment-manage-dates__legend-icon {
                                flex: none;
                                width: 2rem;
                                min-width: unset;
                                height: auto;
                                min-height: unset;
                                aspect-ratio: 1;

                                svg {
                                    width: 100%;
                                    min-width: unset;
                                    height: 100%;
                                    min-height: unset;
                                }
                            }

                            .assignment-manage-dates__legend-label {
                                transform: translateY(0.1em);
                            }
                        }

                        .assignment-manage-dates__input-wrapper,
                        .assignment-manage-dates__select-wrapper {
                            @include white-to-transparent-border;
                            width: 100%;
                            background-color: rgba(255, 255, 255, 1);
                            border-radius: 6px;
                            box-shadow: 0px 4px 4px 0px rgba($secondary-c, 0.09),
                                        0px 4px 4px 0px rgba(255, 255, 255, 0.04) inset;
                            font-weight: 500;
                        }

                        .assignment-manage-dates__input-wrapper {
                            grid-column: 1 /span 1;
                            grid-row: 2 / span 1;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 0.5rem;
                            padding: 1rem;

                            .mat-datepicker-input {
                                transform: translateY(0.1em);
                            }

                            .mat-datepicker-toggle {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                button {
                                    width: 2.8rem;
                                    height: auto;
                                    aspect-ratio: 1;
                                    padding: unset;

                                    .mat-icon {
                                        width: 100%;
                                        min-width: unset;
                                        height: 100%;
                                        min-height: unset;
                                        color: $secondary-c !important;

                                        svg {
                                            width: 100%;
                                            min-width: unset;
                                            height: 100%;
                                            min-height: unset;
                                        }
                                    }
                                }
                            }

                            mat-datepicker {
                                @include visually-hidden;
                            }
                        }

                        .assignment-manage-dates__select-wrapper {
                            grid-column: 2 /span 1;
                            grid-row: 2 / span 1;

                            .mat-mdc-select {
                                height: 100%;

                                .mat-mdc-select-trigger {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    gap: 0.5rem;
                                    height: 100%;
                                    padding: 1rem;

                                    .mat-mdc-select-value {
                                        color: inherit;
                                        transform: translateY(0.1em);
                                    }
                                }

                                .mat-mdc-select-arrow-wrapper {
                                    width: 2.8rem;
                                    height: auto;
                                    aspect-ratio: 1;
                                    background-color: $secondary-c;
                                    mask-image: icon('arrow_drop_down_rounded_700.svg');
                                    mask-size: contain;
                                    mask-repeat: no-repeat;

                                    .mat-mdc-select-arrow {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}