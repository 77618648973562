app-bd-card.card-split-media-info {
    
    mat-card-content {
        height: 100%;

    }
    mat-card-actions.mat-mdc-card-actions  {
        place-content: flex-end;
    }

}