app-order-matching {

    mat-card.activities-card {

        &.order-sentence {

            .mat-mdc-card-content {
                flex-direction: column;
                display: flex;
                justify-content: center;

                #container-for-answers-selected,
                .available-answers {
                    flex-direction: row;
                    display: flex;
                    flex-wrap: wrap;
                    flex: 1;
                    align-items: center;
                    gap: 10px;
                    max-width: inherit;
                    align-content: center;
                    justify-content: center;

                    > * { // Answer

                        min-height: 65px;
                        @include py-0;
                        @include px-2;
                        gap: inherit;
                        transform: translateY(0);
                        flex: 1;
                        height: auto;
                        min-width: 140px;
                        flex-grow: 0;

                        span {
                            text-align: center;
                        }
                    }
                }

                #container-for-answers-selected {
                    order: 1;

                    > * { // Answer
                        pointer-events: none;

                        &.pristine {
                            box-shadow: none;
                            border: none;
                            border-bottom: 3px solid rgba(var(--textdisabled-rgb), 0.8);
                            border-radius: 0;

                            span {
                                display: none;
                            }

                            &:before,
                            &:after {
                                display: none;
                            }
                        }
                    }
                }

                .available-answers {
                    order: 2;
                    align-content: flex-start;

                    > * { // Answer

                        &.was-correct {
                            box-shadow: none;
                            border: none;
                            background-color: rgba(var(--textdisabled-rgb), 0.3);
                            span {
                                display: none;
                            }
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &:not(.order-sentence) {
            @include matching-typology-layout;

            #container-for-answers-selected {
                pointer-events: none;

                .button-order {

                    span {
                        text-align: center;

                        &.answer-position {
                            font-size: #{set-fluid-scale(40px, 80px)};
                        }
                    }
                }
            }
        }
    }
}