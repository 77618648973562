// --------------------------------------------------
// ORDER-MATCHING & TEXT-MATCHING
// --------------------------------------------------

@mixin matching-typology-layout {

    // ----- VARIABLES

    $button-aspect-ratio: calc(16 / 9);
    $min-grid-cell-width: max(10rem, 10vw);
    $max-grid-cell-width: 32.5rem;
    $min-grid-cell-height: calc($min-grid-cell-width / $button-aspect-ratio);
    $max-grid-cell-height: calc($max-grid-cell-width / $button-aspect-ratio);

    // ----- STYLE

    .mat-mdc-card-content {
        @include g-3;
        @include my-auto;
        flex: 1;
        display: grid;
        align-self: center;
        grid-auto-flow: row;
        grid-template-columns: repeat(2, minmax($min-grid-cell-width, $max-grid-cell-width));
        grid-auto-rows: minmax($min-grid-cell-height, $max-grid-cell-height);
        justify-content: center;
        align-content: center;
        max-width: calc(100% - ($spacing-3 * 2));
        max-height: calc(100vh - 40rem);
        padding: unset;

        @supports selector(:has(*)) { // If browser supports :has() selector
            flex: none; // Override previous flex declaration

            @for $i from 2 through 4 { // Calculate mat-card-content ratio based on the number of answers

                &:has(> * > *:nth-child(#{$i}):last-child) {
                    aspect-ratio: calc(2 / ($i / $button-aspect-ratio));
                }
            }
        }

        @media (orientation: landscape) {
            grid-auto-flow: column;
            grid-template-columns: unset;
            grid-auto-columns: minmax($min-grid-cell-width, $max-grid-cell-width);
            grid-template-rows: repeat(2, minmax($min-grid-cell-height, $max-grid-cell-height));
            grid-auto-rows: unset;

            @supports selector(:has(*)) { // If browser supports :has() selector

                @for $i from 2 through 4 { // Calculate mat-card-content ratio based on the number of answers

                    &:has(> * > *:nth-child(#{$i}):last-child) {
                        aspect-ratio: calc($i / (2 / $button-aspect-ratio));
                    }
                }
            }
        }

        #container-for-answers-available, // Left/bottom answer container (order-matching)
        #container-for-answers-selected, // Right/top answer container (order-matching)
        .even-answers, // Left/bottom answer container (text-matching)
        .odd-answers { // Right/top answer container (text-matching)
            display: contents;

            > * { // Answer
                width: auto;
                max-width: 100%;
                height: 100%;
                max-height: 100%;
                aspect-ratio: $button-aspect-ratio;
                padding: unset;

                @media (orientation: landscape) {
                    width: 100%;
                    height: auto;
                }

                img {
                    border-radius: 9px;
                }

                &.button-text { // order-matching & text-matching

                    span, // order-matching
                    p { // text-matching
                        @include p-2;
                        overflow-y: auto;
                        overflow-x: hidden;
                        margin: unset;
                    }
                }
            }

            &:where(#container-for-answers-available, .even-answers) { // Left/bottom answer container

                > * { // Answer

                    @for $i from 1 through 4 { // Enforce grid layout based on index of items in the child list of their parent

                        &:nth-child(#{$i}) {
                            grid-column: 1 /span 1;
                            grid-row: $i / span 1;
                            justify-self: end;
                            align-self: stretch;

                            @media (orientation: landscape) {
                                grid-column: $i / span 1;
                                grid-row: 2 /span 1;
                                justify-self: center;
                                align-self: start;
                            }
                        }
                    }
                }
            }

            &:where(#container-for-answers-selected, .odd-answers) { // Right/top answer container

                > * { // Answer

                    @for $i from 1 through 4 { // Enforce grid layout based on index of items in the child list of their parent

                        &:nth-child(#{$i}) {
                            grid-column: 2 /span 1;
                            grid-row: $i / span 1;
                            justify-self: start;
                            align-self: stretch;

                            @media (orientation: landscape) {
                                grid-column: $i / span 1;
                                grid-row: 1 /span 1;
                                justify-self: center;
                                align-self: end;
                            }
                        }
                    }
                }
            }
        }
    }
}