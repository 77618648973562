 mat-datepicker-content {

    table {
        &.mat-calendar-table {

            th,
            th.mat-mdc-header-cell
            {
                border: none;
            }
            tr,
            tr.mat-mdc-row {
                height: initial;
                box-shadow: none;
            }
        }
    }
}